import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'modalform',
  templateUrl: './modalform.component.html',
  styleUrls: ['./modalform.component.scss']
})
export class ModalformComponent implements OnInit {

  _visible = false



  @Input() showcontinue: boolean;



  @Input() title = 'Hinweis';
  @Input() message = '';
  @Input() type = 'info'; //info,success,warning
  @Input() closebtn_visible = true;

  @Output() closeclick = new EventEmitter<string>();

  @Input() set visible(value) {

    if (value) {
      document.getElementById("modalopenbtn").click();//Öffnen des modalen Bootstrap-Fensters  
    }
    this._visible = value;

  }
  @Output() visibleChange = new EventEmitter<boolean>();


  constructor() { }



  ngOnInit(): void {
  }


  close(result) {
    this.visible = false;
    this.visibleChange.emit(false)
    if (this.closeclick != null)
      this.closeclick.emit(result);

  }








}
