import { DbconfigService } from './dbconfig.service';

import { HttpClient } from '@angular/common/http';
// https://www.netjstech.com/2020/11/custom-async-validator-angular-reactive-form.html

import { Injectable, Component } from '@angular/core';
import { AbstractControl, AsyncValidator, FormGroup, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/**
 * 
 * 
 *  Prüft die Kartennummer gegen die Tabelle "kundenkarten"
 * 
 */






@Injectable({ providedIn: 'root' })
export class KartenCheckoutValidator implements AsyncValidator {

    constructor(private http: HttpClient, private config: DbconfigService) { }
    validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        return this.checkKartennr(control).pipe(
            catchError(() => of(null))
        );
    }

    //HTTP-Service
    //Aufruf in CheckOut
    checkKartennr(control: AbstractControl): Observable<ValidationErrors | null> {
        let kartennummer = control.value;
        control.setErrors(null);
        if (kartennummer == "") {
            return of(null)
        }

        let param = "?operation=cout";
        let json = '{"kartennummer":"' + kartennummer + '"}';
        let sql = JSON.stringify(JSON.parse(json));

        return this.http.post<any>(this.config.url + param, sql).pipe(
            map(
                res => {
                    if (res["success"]) {
                        return null;
                    } else if (res["error"] == "existing request") {
                        return { existingKartennrRequest: true };
                    } else if (res["error"] == "kdnr not found") {
                        return { invalidKartennr: true };
                    } else {
                        return { unknownError: true };
                    }
                }
            )

        )
    }

}