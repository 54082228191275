
import { HttpClient } from '@angular/common/http';
// https://www.netjstech.com/2020/11/custom-async-validator-angular-reactive-form.html

import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, FormGroup, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class IBanValidator implements AsyncValidator {




  iban: any = null
  valid: any = null
  bic: any = null
  kreditinstitut: any = null

  /**
   * 
   * !!! Async Validator !!!
   * 
   * NAch dem Aufuf werden die Daten in den lokalen variablen gespeichert.
   * Jeder weitere Http - Request wird nur ausgeführt, wenn sich die IBAN seit dem letzte Aufruf geändert hat
   */

  constructor(private http: HttpClient) { }

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.checkIban(control.value, control).pipe(
      map(result => (result ? null : { InvalidIban: true })),
      catchError(() => of(null))
    );
  }






  //HTTP-Service
  checkIban(iban: string, control: AbstractControl): Observable<boolean> {
    let url = 'https://openiban.com/validate/' + iban + '?getBIC=true&validateBankCode=true'
    if ((this.iban == iban) || (iban == '')) {
      if (iban == '') {
        //Wenn IBAN LEER
        control.parent.get("bic").setValue('');
        control.parent.get("kreditinstitut").setValue('');
        this.bic = '';
        this.kreditinstitut = '';
        return of(true)
      }
      if (control.parent)
        control.parent.get("bic").setValue(this.bic);
      if (control.parent)
        control.parent.get("kreditinstitut").setValue(this.kreditinstitut);
      return of(this.valid)
    }
    return this.http.get<any>(url).pipe(
      map(
        res => {
          this.iban = iban
          this.valid = res.valid;
          if (res.valid) {
            //Wenn IBAN gültig
            control.parent.get("bic").setValue(res.bankData.bic);
            control.parent.get("kreditinstitut").setValue((res.bankData.name + ' ' + (res.bankData.zip ? res.bankData.zip + ' ' : '') + (res.bankData.city ? res.bankData.city : '')).trim());
            this.bic = res.bankData.bic;
            this.kreditinstitut = (res.bankData.name + ' ' + (res.bankData.zip ? res.bankData.zip + ' ' : '') + (res.bankData.city ? res.bankData.city : '')).trim();
          } else {
            //Wenn IBAN UNgültig
            control.parent.get("bic").setValue('');
            control.parent.get("kreditinstitut").setValue('');
            this.bic = '';
            this.kreditinstitut = '';
          }
          return res.valid
        })
    )


  }




}