import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'dropdownlookup',
  template: `
        <div  *ngIf="layout<=1" [formGroup]="form">
            <label [for]="field.name" class="form-label we_label">{{field.label}}<span *ngIf="form.get(field.name)"> <strong *ngIf="isRequiredField(field.name)">*</strong></span></label>
            <select [id]="field.name" class="form-select  mb-3 we_input" aria-label=".form-select-lg example" [formControlName]="field.name" (change)="Change($event)">
                <option *ngFor="let item of field.options" [value]="item[key]">{{item[displayvalue]}}</option>
            </select>

            <div class="text-danger" *ngIf="checkError(field)">
                {{checkError(field)}}
            </div>  
        </div>

        <div  *ngIf="layout==2" [formGroup]="form" class="form-floating">
          <label *ngIf="!form.get(field.name).value"   class="form-label" style="padding-left:20px">
              {{field.label}}<span *ngIf="form.get(field.name)"> <strong *ngIf="isRequiredField(field.name)">*</strong></span>
          </label>
          <label *ngIf="form.get(field.name).value"   class="form-label" style="padding-left:20px;transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);opacity: 0.65;">        
            {{field.label}}<span *ngIf="form.get(field.name)"> <strong *ngIf="isRequiredField(field.name)">*</strong></span>    
          </label>
          <select [id]="field.name" class="form-select  mb-3 we_input" style="padding-top:1.65rem;margin: 0px !important" aria-label=".form-select-lg example" [formControlName]="field.name" (change)="Change($event)">
            <option *ngFor="let item of field.options" [value]="item[key]">{{item[displayvalue]}}</option>
          </select>

          <div class="text-danger" *ngIf="checkError(field)">
                {{checkError(field)}}
            </div>  
        </div>

    `
})
export class DropDownLookupComponent {

  @Output() onChange = new EventEmitter<string>();
  @Input() field: any = {};

  @Input() key: any = {};
  @Input() displayvalue: any = {};

  @Input() form: FormGroup;
  @Input() id: any = '';
  @Input() layout: number = 1;
  constructor() {

  }

  Change(event) {
    this.onChange.emit(event)
  }

  /**
   * transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  font-size:small;padding-top:0px; padding-left:20px
   */

  isRequiredField(field: string) {
    //Ermittelt, ob das Feld "Required" ist
    const form_field = this.form.get(field);
    if (!form_field.validator) {
      return false;
    }
    const validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }




  checkError(field): string {
    if (this.form.get(field.name).hasError('required') && this.form.get(field.name).touched)
      return field.label + ' muss angegeben werden'
    if (this.form.get(field.name).hasError('NoEmailMatch') && this.form.get(field.name).touched)
      return ' Die Email-Adresse wurde nicht korrekt wiederholt'
    if (this.form.get(field.name).hasError('NoKdnrSelected') && this.form.get(field.name).touched)
      return ' Es muss mind. 1 Kundennummer angegeben werden'

    return null
  }
}

