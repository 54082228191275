import { ActivatedRoute } from '@angular/router';
import { GkarteService } from './../gkarte.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aktivierung',
  templateUrl: './aktivierung.component.html',
  styleUrls: ['./aktivierung.component.scss']
})
export class AktivierungComponent implements OnInit {

  step = 1;// step 1 = Aktivierung  step 2 = Aktivierung erfolgreich
  id = "";
  secureid = ""
  status = "";

  constructor(private gkarteService: GkarteService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.secureid = params['secureid'];

      this.checkActive();
    }
    )


  }


  activate() {
    this.gkarteService.activateBestellung(this.id, this.secureid).subscribe(res => {
      if (res.status = "OK") {
        this.status = 'completed';
        this.step = 2;
      }
    })

  }


  checkActive() {
    this.gkarteService.checkActive(this.id, this.secureid).subscribe(res => {
      res = JSON.parse(res)
      this.status = res.status;
    })

  }



}
