import { AlertService } from '../alert/alert.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { EMPTY } from 'rxjs';


@Injectable()
export class HTTPErrorInterceptorService implements HttpInterceptor {

  constructor(private alertService: AlertService) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {



    return next.handle(request).pipe(catchError(err => {
      console.log('FEHLERBERICHT, Http-Interceptor', err);
      let status = err.status;
      let message = err.error;

      if (err.status === 401) {
        // Authentifizierung fehlgeschlagen
        //this.authenticationService.logout();
        //location.reload(true);

        //this.messageService.addWarning(message);
        this.alertService.error(message);
        return EMPTY;

      }

      switch (status) {
        case 400: {
          //Allgemeiner Fehler DBOperation: Select / Update / Insert / Delete
          //this.messageService.addWarning(message);
          this.alertService.error(message, false, 7000);
          break;
        }

        case 409: {
          //Eine vom Server gesendete Nachricht, die einen Integritätskonflikt enthält
          return throwError(message);//Fehlermeldung wird weiter gereicht
          break;
        }

        case 503: {
          //Verbindunsproblem DB-Connect
          //this.messageService.addWarning(message);
          this.alertService.error(message);
          break;
        }

        default: {
          //this.messageService.addWarning("Verbindung fehlgeschlagen. Versuchen Sie es bitte später wieder.  -  (" + err.message + ")");
          this.alertService.error("Verbindung fehlgeschlagen. Versuchen Sie es bitte später wieder.  -  (" + err.message + ")", false, 7000);


        }
      }
      return EMPTY; //Fehler ist behandelt und wird nicht weitergegeben
    }))
  }
}



