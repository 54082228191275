import { SharedModule } from './../shared/shared.module';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { BestellungComponent } from './bestellung/bestellung.component';
import { GkarteService } from './gkarte.service';
import { CtrlsModule } from '../ctrls/ctrls.module';
import { PagefooterComponent } from './pagefooter/pagefooter.component';
import { IbanService } from '../common/iban.service';
import { GeonamesService } from '../common/geonames.service';
import { AngularFormsInputMasksModule } from 'angular-forms-input-masks';
import { AktivierungComponent } from './aktivierung/aktivierung.component';
import { PageheaderComponent } from './pageheader/pageheader.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { VerlustmeldungComponent } from './verlustmeldung/verlustmeldung.component';
import { CheckoutComponent } from './checkout/checkout.component';




@NgModule({
  declarations: [BestellungComponent, PagefooterComponent, AktivierungComponent, PageheaderComponent, VerlustmeldungComponent, CheckoutComponent,],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CtrlsModule,
    SharedModule,
    AngularFormsInputMasksModule,
    AutocompleteLibModule

  ],
  exports: [
    BestellungComponent,PagefooterComponent,PageheaderComponent

  ],
  providers: [
    GkarteService,
    IbanService,
    GeonamesService
  ]
})
export class GkarteModule { }
