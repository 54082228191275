import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DbconfigService } from './dbconfig.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeonamesService {

  constructor(private http: HttpClient, private config: DbconfigService) { }


  /**********************Request an Server, plz und Stadt werden aus einer statischen Textdatei gelesen (quelle: geonames.org) *************************/
  getCities(_country: string, _zip: string) {
    //Liest die Orte aus der Textdatei
    let param = "?operation=getcities";
    let sql = JSON.parse('{"country":"' + _country + '","zip":"' + _zip + '"}');
    return this.http.post<any>(this.config.url + param, sql).pipe(
      map(
        res => {
          //res['data'].unshift('');//Leeres Element am Anfang
          return res['data']
        }
      )
    )
  }


  /**********************Request an Server, plz und Stadt werden der StreetData-Tabelle gelesen (quelle: https://datapuls.de/) *************************/
  _getCities(_country: string, _zip: string) {
    //Liest die Orte aus der StreetData-Tabelle
    let param = "?operation=select";
    let sql = 'select DISTINCT ortsname as city,postleitzahl as zip, "DE" as country from streetdata where postleitzahl="' + _zip + '"'
    return this.http.post<any>(this.config.url + param, sql).pipe(
      map(
        res => {
          return res['data']
        }
      )
    )
  }




  getStreets(search: string) {
    //let param = "?operation=select";
    //let sql = 'select distinct strasse from streetdata where postleitzahl="' + search + '" order by strasse'



    let param = "?operation=getstreets";
    let json = '{"search":"' + search + '"}';
    let sql = JSON.stringify(JSON.parse(json));



    return this.http.post<any>(this.config.url + param, sql).pipe(
      map(
        res => {
          let item = res['data']
          var strassen = item.map(function (item) {
            return item['strasse'];
          });
          strassen.unshift('');//Leeres Element am Anfang
          return strassen;
        }
      )
    )




  }










}
