import { DbconfigService } from './../../common/dbconfig.service';
import { Router } from '@angular/router';
import { VerlustmeldungService } from './verlustmeldung.service';
import { Component, OnInit } from '@angular/core';
import { CustomValidators } from 'src/app/common/custom-validators';
import { Observable, of, Subscription } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-verlustmeldung',
  templateUrl: './verlustmeldung.component.html',
  styleUrls: ['./verlustmeldung.component.scss']
})
export class VerlustmeldungComponent implements OnInit {

  step: number = -1 //step -1:Vorschaltbild grüne Karte/weißeKarte step 0: Dialogfeld "Grüne Karte mit noch mehr....  "   step 1: Verlustmeldung, step 2: Erfolg bei phys. Karte, step 3: Erfolg bei Grüner Karte
  layout = 2;
  ErrorMsg = '';
  kartenart = ''; //möglich:  'gruenekarte' oder 'physikalisch';

  checkkdnrSubs: Subscription;
  sendVerlustSubs: Subscription;
  verlustgkSubs: Subscription;




  msgkundennummer = 'Die Kundennummer Ihrer GRÜNEN KARTE finden Sie auf Ihrer monatlichen Abrechnung.'
  msgemail = 'E-Mail-Adresse, an welche Sie Ihre monatliche Rechnung der GRÜNEN KARTE erhalten.'

  constructor(private formBuilder: FormBuilder,
    private verlustmeldungService: VerlustmeldungService,
    private router: Router,
    private titleService: Title,
    private conf: DbconfigService
  ) { }

  Form = this.formBuilder.group({
    id: [0],
    kundennummer: [null, [Validators.required]],
    email: ['', [CustomValidators.emailValidator]],
  }
  );



  ngOnInit(): void {
    this.titleService.setTitle('STADTWERK AM SEE - Verlustmeldung');
    this.Form.get('kundennummer').valueChanges.subscribe(event => this.ErrorMsg = '')

  }



  weissekarteClick() {

    this.step = 0;

  }

  gruenekarteClick() {

    this.step = 1;

  }



  onChange(event) {
    this.ErrorMsg = '';
  }

  onFocus(event) {
    this.ErrorMsg = '';
  }

  onBlur(event) {
    this.ErrorMsg = '';
  }



  pruefen() {
    this.Form.markAllAsTouched();
    this.Form.updateValueAndValidity();
    this.ErrorMsg = '';
    let cnt = 0;
    if (this.Form.invalid) {
      this.ErrorMsg = 'Die Eingaben sind nicht korrekt. Bitte überprüfen Sie das Formular '
      for (var field in this.Form.controls) {
        cnt = cnt + 1;
        if (this.Form.controls[field].errors) {
          if (this.Form.controls[field].errors['required']) {
            this.ErrorMsg = this.ErrorMsg + ': Pflichteingabe fehlt '
            console.log(this.Form.controls[field], cnt);//DIESES LOG NICHT ENTFERNEN
            break;
          }
          if (this.Form.controls[field].errors['invalidEmail']) {
            this.ErrorMsg = this.ErrorMsg + ': Die Email-Adresse ist ungültig '
            break;
          }
        }
      }
      window.scroll(0, 0);//Nach Oben Scrollen
      return;
    }


    this.checkkdnrSubs = this.verlustmeldungService.checkKundeNummer(this.Form.get('kundennummer').value).subscribe(
      res => {
        this.kartenart = res['kartenart']
        if (this.kartenart == '') {
          this.ErrorMsg = 'Diese Kundennummer ist nicht registriert, bitte überprüfen...'
        }
      }
    )

  }




  sperren() {
    let email = this.Form.get('email').value;
    let kundennummer = this.Form.get('kundennummer').value;
    if (!email) {
      this.Form.get('email').setErrors({ 'required': true })
      return false;
    }
    if (!kundennummer) {
      this.Form.get('kundennummer').setErrors({ 'required': true })
      return false;
    }

    this.Form.markAllAsTouched();
    this.Form.updateValueAndValidity();
    this.ErrorMsg = '';

    if (this.Form.invalid) {
      this.ErrorMsg = 'Die Eingaben sind nicht korrekt. Bitte überprüfen Sie das Formular '
      return false;
    }


    this.sendVerlustSubs = this.verlustmeldungService.checkEmail(email, kundennummer).subscribe(
      res => {
        if (!res) {
          this.ErrorMsg = 'Bitte überprüfen Sie die Kundennummer und die Email-Adresse. '
        } else {
          this.verlustgkSubs = this.verlustmeldungService.verlustgk(kundennummer, email).subscribe(res => {
            if (res.status == 'OK') {
              this.step = 3;

            }
          })
        }

        return res;
      }
    )



  }



  verlustPhsys(kundenummer, karte) {
    //karte : "kundenkarte" oder "GRÜNE KARTE"
    //mail -> kundennummer , kartenart
    this.sendVerlustSubs = this.verlustmeldungService.verlustPhsys(kundenummer, karte).subscribe(res => {
      if (res.status == 'OK') {
        if (karte == "GRÜNE KARTE") {
          if (this.conf.configuration['urlbestellung']) {
            window.location.href = this.conf.configuration['urlbestellung'];
          }
          else {
            this.router.navigate(['bestellung']);

          }
        } else {
          this.step = 2;
        }


      } else {
        this.ErrorMsg = 'Es ist ein Fehler aufgetreten. Die Verlustmeldung konnte nicht übermittelt werden.'
      }

    });

  }




  weiterzurBestellungGrueneKarte() {
    //Aufruf aus Verlustmeldung (weisse Karte, Button "JA")
    if (this.conf.configuration['urlbestellung']) {
      window.location.href = this.conf.configuration['urlbestellung'];
    }
    else {
      this.router.navigate(['bestellung']);

    }

  }

  VerlustmeldungAlteKarte() {
    window.location.href = "https://www.stadtwerk-am-see.de/de/Formulare/Verlustmeldung-Karte.html ";
  }



  validationPending() {
    let result =
      (this.checkkdnrSubs && !this.checkkdnrSubs.closed) ||
      (this.sendVerlustSubs && !this.sendVerlustSubs.closed) ||
      (this.verlustgkSubs && !this.verlustgkSubs.closed)

    return result;
  }

  bestell_url() {
    if (this.conf.configuration['urlbestellung'])
      return this.conf.configuration['urlbestellung']
    else
      return '/bestellung';


  }




}
