import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class IbanService {


  iban: any = null
  valid: any = null
  bic: any = null
  kreditinstitut: any = null

  constructor(private http: HttpClient) { }

  getIban(iban: number | string): any {
    let url = 'https://openiban.com/validate/' + iban + '?getBIC=true&validateBankCode=true'
    return this.http.get<any>(url).pipe(
      map(
        res => {
          return res;
        })
    )
  }



}

