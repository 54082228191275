import { VerlustmeldungComponent } from './gkarte/verlustmeldung/verlustmeldung.component';
import { BestellungComponent } from './gkarte/bestellung/bestellung.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AktivierungComponent } from './gkarte/aktivierung/aktivierung.component';
import { CheckoutComponent } from './gkarte/checkout/checkout.component';






const routes: Routes = [
  { path: '', redirectTo: 'bestellung', pathMatch: 'full' },
  { path: 'bestellung', component: BestellungComponent },
  { path: 'bestellung/:mode', component: BestellungComponent },
  { path: 'bestellung/:autostrom', component: BestellungComponent },
  { path: 'aktivierung/:id/:secureid', component: AktivierungComponent },
  { path: 'verlustmeldung', component: VerlustmeldungComponent },  
  { path: 'checkout', component: CheckoutComponent},

  { path: '**', component: BestellungComponent },


];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
