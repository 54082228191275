<pageheader></pageheader>

<div *ngIf="step==1" class="container-md we_checkout">
    <form autocomplete="off" class="row g-3 we_form" [formGroup]="Form">







        <div class="col-md-6">
            <h4 class="we_header">Check-out bei Bus, Bahn oder Katamaran melden</h4>
        </div>
        <div class="col-md-6">
        </div>


        <div class="col-md-6">
            Sollten Sie im Bus, in der Bahn oder auf dem Katamaran einen Check-out vergessen haben, tragen Sie hier
            bitte den Check-out nach.
        </div>

        <div class="col-md-6">
        </div>



        <div class="col-md-6">
            <!--
            <textinput [layout]="layout" [field]="{name:'kartennummer',label:'Kartennummer',maxlength:40}" [form]="Form" (onChange)="onChange($event)"></textinput>
            -->
            <div class="form-floating">
                <input (change)="onChange($event)" type="text" unmasked="true" angularFormsMask="DD.DDD.DDD"
                    class="form-control we_input_knr we_input" placeholder="Kartennummer der bestehenden GRÜNEN KARTE"
                    maxlength="40" id="kartennummer" formControlName="kartennummer">
                <label for="kartennummer" class="we_label">Kartennummer <strong>*</strong></label>
                <label class="we_leftlabel_knr">6.291-</label>
                <label class="we_rightlabel_knr">-x</label>
                <div class="text-danger"
                    *ngIf="Form.get('kartennummer').hasError('required') && Form.get('kartennummer').touched">
                    Die Kartennummer muss angegeben werden
                </div>
                <div class="text-danger"
                    *ngIf="Form.get('kartennummer').hasError('invalidKartennr') && Form.get('kartennummer').touched">
                    Die Kartennummer ist ungültig
                </div>
                <div class="text-danger"
                    *ngIf="Form.get('kartennummer').hasError('existingKartennrRequest') && Form.get('kartennummer').touched">
                    Für diese Kundennummer existiert bereits eine Anfrage
                </div>
                <div class="text-danger"
                    *ngIf="Form.get('kartennummer').hasError('unknownError') && Form.get('kartennummer').touched">
                    Ein unbekannter Fehler ist aufgetreten
                </div>
            </div>
        </div>
        <div class="col-md-6">
        </div>



        <div class="col-md-6">
            <textinput [layout]="layout" [field]="{name:'haltestelle',label:'Haltestelle',maxlength:40}" [form]="Form"
                (onChange)="onChange($event)"></textinput>
        </div>
        <div class="col-md-6">
        </div>


        <div class="col-md-6">
            <textinput [layout]="layout" [field]="{name:'linie',label:'Linie',maxlength:40}" [form]="Form"
                (onChange)="onChange($event)"></textinput>
        </div>
        <div class="col-md-6">
        </div>


        <div class="col-md-3">
            <textinput [layout]="layout" [field]="{name:'datum',label:'Datum',type:'date', maxlength:40}" [form]="Form"
                (onChange)="onChange($event)"></textinput>
        </div>
        <div class="col-md-3">

            <textinput [layout]="layout" [field]="{name:'zeit',label:'Uhrzeit',type:'time', maxlength:40}" [form]="Form"
                (onChange)="onChange($event)"></textinput>

        </div>


        <div class="col-md-6">
        </div>



        <div class="col-md-6">
            <b>*</b> Pflichtfelder sind mit dem Stern markiert
        </div>


        <div class="col-md-6">
            <!--Senden-Button-->
            <button type="button" [disabled]="validationPending()" (click)="absenden()"
                class="btn btn-primary we_button fullwidth_button" data-bs-toggle="tooltip" data-bs-placement="top"
                title="Checkout melden">
                Checkout melden</button>
        </div>



        <div class="col-md-6">
        </div>


        <div class="col-md-6" *ngIf="validationPending()">
            <!--Bootstrap-Spinner-->
            <div class="d-flex justify-content-center">
                <div class="d-flex align-items-center">
                    <div class="spinner-border ms-auto p-4" role="status" aria-hidden="true"></div>
                    <strong class="ms-4"> Senden.... </strong>
                </div>

            </div>
        </div>



    </form>
</div>



<div *ngIf="step==2" class="container-md we_checkout">
    <div class="row g-3">
        <h4 style="padding-left:0" class="we_header">Check-out erfolgreich gemeldet</h4>
        <!--h5 class="we_subheader">Ihre Bestellung wurde erfolgreich gesendet.</h5 -->
        Kartennummer: {{Form.value['kartennummer']}}<br>
        Haltestelle: {{Form.value['haltestelle']}}<br>
        Linie: {{Form.value['linie']}}<br>
        Datum: {{Form.value['datum']|date}}<br>
        Uhrzeit: {{Form.value['zeit']}} Uhr
        <br><br>
        Vielen Dank für Ihre Mitteilung. Unser Partner bodo wird den Check-out auf Ihrer GRÜNEN KARTE hinterlegen. Bitte
        prüfen Sie Ihre Abrechnung.

    </div>


</div>



<div *ngIf="ErrorMsg!==''" class="position-fixed top-0  p-3 text-center bg-danger text-white" style="width:100%">
    {{ErrorMsg}}</div>
<pagefooter></pagefooter>