//import { SharedModule } from './shared/shared.module';

import { AlertComponent } from './alert/alert.component';
import { AlertService } from 'src/app/alert/alert.service';

import { DbconfigService } from './common/dbconfig.service';

import { HTTPErrorInterceptorService } from './interceptor/httperrorinterseptor.service';
import { HttpauthinterseptorService } from './interceptor/httpauthinterseptor.service';
import { of, Observable, ObservableInput } from '../../node_modules/rxjs';
import { map, catchError } from 'rxjs/operators'

import { BrowserModule,Title } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE, APP_INITIALIZER } from '@angular/core';
import { registerLocaleData, CommonModule } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GkarteModule } from './gkarte/gkarte.module';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthenticationService } from './common/authentication.service';





//LESEN DER KONFIGURATION UND SPEICHER IN "DbconfigService"
//https://davembush.github.io/where-to-store-angular-configurations/
function load(http: HttpClient, config: DbconfigService): (() => Promise<boolean>) {
  return (): Promise<boolean> => {
    return new Promise<boolean>((resolve: (a: boolean) => void): void => {
      http.get('./config/config.json') //WICHTIG:::: EINTRAG IN
        .pipe(
          map((x: DbconfigService) => {
            console.log('config.json gelesen:', x);
            config.url = x.url;
            config.configuration = x;
            resolve(true);
          }),
          catchError((x: { status: number }, caught: Observable<void>): ObservableInput<{}> => {
            if (x.status !== 404) {
              resolve(false);
            }
            config.url = '';
            console.log('FEHLER beim LESEN config.json: url des REST-SERVER NICHT GESETZT');
            resolve(true);
            return of({});
          })
        ).subscribe();
    });
  };
}



registerLocaleData(localeDe, 'de-DE');

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
  ],
  imports: [
    GkarteModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    //SharedModule
  ],
  providers: [
    Title,
    DbconfigService,
    AlertService,
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPErrorInterceptorService,
      multi: true
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpauthinterseptorService,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR'
    },
    {
      //Konfiguration einlesen
      provide: APP_INITIALIZER,
      useFactory: load,
      deps: [
        HttpClient,
        DbconfigService,
      ],
      multi: true
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
