//DIESER SERVICE WIRD INITIALISIERT IN "app.module.ts"


import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DbconfigService {

  //public url = "http://localhost/restserver/index.php";
  //public url= "http://p559334.webspaceconfig.de/restserver/index.php";
  public url:string;
  public url_placeholder='#URL#';
  public configuration:any;

  constructor() { }
}
