import { KartenCheckoutValidator } from './../../common/kartennrvalidatorcheckout';
import { CheckoutService } from './checkout.service';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/common/custom-validators';
import { DbconfigService } from 'src/app/common/dbconfig.service';
import { Title } from '@angular/platform-browser';



@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  ErrorMsg = '';
  step = 1;
  layout = 2;
  checkOutSubs: Subscription



  Form = this.formBuilder.group({
    id: [0],
    kartennummer: [null, [Validators.required]],
    haltestelle: [null, [Validators.required]],
    linie: [null, [Validators.required]],
    datum: [null, [Validators.required]],
    zeit: [null, [Validators.required]],

  }
  );


  constructor(
    private formBuilder: FormBuilder,

    private router: Router,
    private conf: DbconfigService,
    private checkoutService: CheckoutService,
    private kartenValidator: KartenCheckoutValidator,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.Form.get('kartennummer').setAsyncValidators(this.kartenValidator.validate.bind(this.kartenValidator))
    this.titleService.setTitle('STADTWERK AM SEE-Checkout');
  }

  onChange(event) {
    this.ErrorMsg = '';
  }

  onFocus(event) {
    this.ErrorMsg = '';
  }

  onBlur(event) {
    this.ErrorMsg = '';
  }




  absenden() {
    this.Form.markAllAsTouched();
    this.Form.updateValueAndValidity();
    this.ErrorMsg = '';
    let cnt = 0;
    if (this.Form.invalid) {
      this.ErrorMsg = 'Die Eingaben sind nicht korrekt. Bitte überprüfen Sie das Formular '
      for (var field in this.Form.controls) {
        cnt = cnt + 1;
        if (this.Form.controls[field].errors) {
          if (this.Form.controls[field].errors['required']) {
            this.ErrorMsg = this.ErrorMsg + ': Pflichteingabe fehlt '
            console.log(this.Form.controls[field], cnt);//DIESES LOG NICHT ENTFERNEN
            break;
          }
          if (this.Form.controls[field].errors['invalidEmail']) {
            this.ErrorMsg = this.ErrorMsg + ': Die Email-Adresse ist ungültig '
            break;
          }
        }
      }
      window.scroll(0, 0);//Nach Oben Scrollen
      return;
    }


    this.checkOutSubs = this.checkoutService.checkout(this.Form.value).subscribe(
      res => {
        if (res.status == 'OK') {
          this.step = 2;
        } else {
          this.ErrorMsg = 'Fehler, Checkout konnte nicht durchgeführt werden.'
        }
      }
    );


    // 77078 verweist auf zwei Datensätze, mit unterschiedlichen Kundennummern ????????????????????????????



  }

  validationPending() {
    let result = this.Form.get('kartennummer').pending || (this.checkOutSubs && !this.checkOutSubs.closed);
    return result;
  }


}
