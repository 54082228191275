export class Bestellung {
    id: number = 0; //int(11) NOT NULL COMMENT 'Interner Primärschlüssel',
    bid: number = 0; //int(11) NOT NULL COMMENT 'ID der Bestellung',
    gebuehr: number = 0;//` int(11) NOT NULL COMMENT 'Art der Kartengebühr (lt.Checkboxen in der Bestellung)',
    opt_in_newsletter: any = 0;//WICHTIG: "any"  int(11) DEFAULT NULL COMMENT 'Newsletter (1=ja  0=neutral)',
    opt_in_MaFo: any = 0;//WICHTIG: "any" int(11) DEFAULT NULL COMMENT 'Marktforschung (1=ja  0=neutral)',
    online_kommunikation: number = 0;// int(11) NOT NULL COMMENT 'Wenn email hinterlegt, dann 1',
    bodo: number = 1; //int(11) NOT NULL COMMENT 'immer 1',
    parkhaus: number = 0; //int(11) NOT NULL COMMENT 'immer 1',


    kdnr_strom: string = "";//` varchar(8) DEFAULT NULL COMMENT 'KundenNr Strom',
    kdnr_gas_waerme: string = "";//` varchar(8) DEFAULT NULL COMMENT 'KundenNr Gas und Wärme',
    kartenart: string = "";//` varchar(20) NOT NULL COMMENT 'Art der Karte (grüne karte / physikalisch)',

    anrede: string = "";//` varchar(20) NOT NULL COMMENT 'Anrede',
    titel: string = "";//` varchar(20) DEFAULT NULL COMMENT 'Titel',
    firma: string = "";//` varchar(50) DEFAULT NULL COMMENT 'Firma',
    name: string = "";//` varchar(50) NOT NULL COMMENT 'Nachname',
    vorname: string = "";//` varchar(50) NOT NULL COMMENT 'Vorname',
    namenszusatz: string = "";//` varchar(50) DEFAULT NULL COMMENT 'Namenszusatz',
    strasse: string = "";//` varchar(120) NOT NULL COMMENT 'Strasse',
    hausnr: string = "";//` varchar(4) NOT NULL COMMENT 'Hausnummer',
    hausnrzusatz: string = "";//` varchar(4) DEFAULT NULL COMMENT 'Hausnummer Zusatz',
    plz: string = "";//` varchar(10) NOT NULL COMMENT 'Plz',
    ort: string = "";//` varchar(120) NOT NULL COMMENT 'Ort',
    telefon: string = "";//` varchar(30) DEFAULT NULL COMMENT 'Telefon',
    handy: string = "";//` varchar(20) DEFAULT NULL COMMENT 'Telefon mobil',
    email: string = "";//` varchar(70) NOT NULL COMMENT 'Email',
    geburtsdatum: string = "";//` date NOT NULL COMMENT 'Geburtsdatum',
    kreditinstitut: string = "";//` varchar(58) NOT NULL COMMENT 'Kreditinstitut',
    bic: string = "";//` varchar(11) NOT NULL COMMENT 'BIC',
    iban: string = "";//` varchar(35) NOT NULL COMMENT 'IBAN',
    kontoinhaber: string = "";//` varchar(70) NOT NULL COMMENT 'Kontoinhaber',
    abschlussdatum: string = "";//` AUTOMATISCH datetime NOT NULL DEFAULT CURRENT_TIMESTAMP COMMENT 'Abschlußdatum',
    kndnr_erstkarte: string = "";//` varchar(8) NOT NULL COMMENT 'Pflicht bei Zweitkarte',
    kundenkarte: string = "";//` varchar(120) DEFAULT NULL COMMENT 'Bestehende Kundenkarten (mehrere),

    secureid: string = "";//` varchar(15) NOT NULL,
    mail_versendet: string = "";//` datetime DEFAULT NULL,
    mail_erinnert: string = "";//` datetime DEFAULT NULL,
    mail_bestaetigt: string = "";//` datetime DEFAULT NULL
    uebertragen: string = "";//` datetime DEFAULT NULL
    mein_auto_strom: string = ""; // MEIN AUTO STROM

}
