import { Bestellung } from './Bestellung';
import { HttpClient } from '@angular/common/http';
import { DbconfigService } from './../common/dbconfig.service';
import { Injectable } from '@angular/core';
import { of, Observable, observable } from 'rxjs';
import { delay, map, mergeMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})


export class GkarteService {

    public v_gk = 'Grüne Karte';
    public v_ph = 'Physikalisch';


    title = 'Bestellung'
    constructor(private config: DbconfigService, private http: HttpClient) { }

    alteKdnr = "";



    getSecureId() {
        // Math.random should be unique because of its seeding algorithm.
        // Convert it to base 36 (numbers + letters), and grab the first 9 characters
        // after the decimal.
        return '_' + Math.random().toString(36).substr(2, 12);
    };




    getVorhandeneKarten(k, k2, mode) {
        //k =kundennummer
        //k2=kundennummer oder kartennummer (abhängig von mode)

        //Ermittelt alle Karten die zu sperren sind,falls der Kunde eine Neue Karte bestellt
        // ODER
        //Ermittelt die Kundennummern GAS/WÄRME falls der Kunde eine Zweitkarte bestellt
        if (!k && !k2) {
            return of([])
        }

        let param = '';
        let sql = '';

        if (mode == 1) {
            //  param = "?operation=select";
            //  sql = 'Select kartennummer from ver_traege where    (kartentyp = "Physikalisch") and  (status="aktiv")  and     ((fernwaerme is not null) or (gas is not null)  or(strom is not null))  and (knd_nr1 in ("' + k + '","' + k2 + '"))'


            let param = "?operation=vhk";
            let json = '{"k":"' + k + '","k2":"' + k2 + '"}';
            let sql = JSON.stringify(JSON.parse(json));

            return this.http.post<any>(this.config.url + param, sql).pipe(
                map(
                    res => {
                        let a = res['data']
                        var s = a.map(function (a) {
                            return a['kartennummer'];
                        });
                        return s;
                    })
            )
        }

        if (mode == 2) {
            //param = "?operation=select";
            //sql = 'Select vertragskonto_strom,vertragskonto_gas_waerme from kunden_karten where kundennummer="' + k + '" and externe_vertragsnummer="' + k2 + '" '


            let param = "?operation=knrgw";
            let json = '{"k":"' + k + '","k2":"' + k2 + '"}';
            let sql = JSON.stringify(JSON.parse(json));

            return this.http.post<any>(this.config.url + param, sql).pipe(
                map(
                    res => {
                        return res['data']
                    })
            )
        }

        return of([]) //Defaultwert, leeres Array
    }





    checkKartenArt(kdnr, kdnr2: string) {
        //Hier werden alle Datensätze geladen, die für die Kundenummern STROM oder GAS/WÄRME vorhanden sind

        let param = "?operation=chkka";
        let json = '{"kdnr":"' + kdnr + '","kdnr2":"' + kdnr2 + '"}';
        let sql = JSON.stringify(JSON.parse(json));

        return this.http.post<any>(this.config.url + param, sql)

    }


    saveBestellung(values, mode, autostrom): Observable<any> {
        //Speicher eine Bestellung (values) in Abhängigkeit der gewählten Karte (mode)



        let bestellung = new Bestellung;

        //bestellung.id = 0; //int(11) NOT NULL COMMENT 'Interner Primärschlüssel',
        //bestellung.bid = 0; //int(11) NOT NULL COMMENT 'ID der Bestellung',
        bestellung.gebuehr = 0;//` int(11) NOT NULL COMMENT 'Art der Kartengebühr (lt.Checkboxen in der Bestellung)',
        if (mode == 1) { bestellung.gebuehr = 0 } //Karte (OHNE Jahresgebühr )
        if (mode == 2) { bestellung.gebuehr = 1 } //Zweitkarte (geringe Jahresgebühr 7,50)
        if (mode == 3) { bestellung.gebuehr = 2 } //Karte mit Jaresgebühr (15,00)

        bestellung.opt_in_newsletter = 0;//WICHTIG: "any"  int(11) DEFAULT NULL COMMENT 'Newsletter (1=ja  0=neutral)',
        if (values['opt_in_Newsletter']) { bestellung.opt_in_newsletter = 1 }


        bestellung.opt_in_MaFo = 0;//WICHTIG: "any" int(11) DEFAULT NULL COMMENT 'Marktforschung (1=ja  0=neutral)',

        if (values['opt_in_MaFo']) { bestellung.opt_in_MaFo = 1 }

        bestellung.online_kommunikation = 0;// int(11) NOT NULL COMMENT 'Wenn email hinterlegt, dann 1',
        if (values['email'] !== "") { bestellung.online_kommunikation = 1 };

        bestellung.bodo = 1; //int(11) NOT NULL COMMENT 'immer 1',
        bestellung.parkhaus = 1; //int(11) NOT NULL COMMENT 'immer 1',

        if (mode == 1) {
            bestellung.kdnr_strom = values['kundennummerstrom'];//` varchar(8) DEFAULT NULL COMMENT 'KundenNr Strom',
            bestellung.kdnr_gas_waerme = values['kundennummerwaerme'];//` varchar(8) DEFAULT NULL COMMENT 'KundenNr Gas und Wärme',
        }

        bestellung.kartenart = this.v_gk;//` varchar(20) NOT NULL COMMENT 'Art der Karte (grüne karte / physikalisch)',

        bestellung.anrede = values['anrede'];//` varchar(20) NOT NULL COMMENT 'Anrede',
        bestellung.titel = values['titel'];//` varchar(20) DEFAULT NULL COMMENT 'Titel',
        bestellung.firma = values['firma'];//` varchar(50) DEFAULT NULL COMMENT 'Firma',
        bestellung.name = values['name'];//` varchar(50) NOT NULL COMMENT 'Nachname',
        bestellung.vorname = values['vorname'];//` varchar(50) NOT NULL COMMENT 'Vorname',
        bestellung.namenszusatz = values['namenszusatz'];//` varchar(50) DEFAULT NULL COMMENT 'Namenszusatz',

        if (values['anrede'] == 'Firma') { bestellung.namenszusatz = values['ansprechpartner'] }

        bestellung.strasse = values['strasse'];//` varchar(120) NOT NULL COMMENT 'Strasse',
        bestellung.hausnr = values['hausnummer'];//` varchar(4) NOT NULL COMMENT 'Hausnummer',
        bestellung.hausnrzusatz = values['hausnummerzusatz'];//` varchar(4) DEFAULT NULL COMMENT 'Hausnummer Zusatz',
        bestellung.plz = values['plz'];//` varchar(10) NOT NULL COMMENT 'Plz',
        bestellung.ort = values['ort'];//` varchar(120) NOT NULL COMMENT 'Ort',
        bestellung.telefon = values['telefonnummer'];//` varchar(30) DEFAULT NULL COMMENT 'Telefon',
        bestellung.handy = values['handynummer'];//` varchar(20) DEFAULT NULL COMMENT 'Telefon mobil',
        bestellung.email = values['email'];//` varchar(70) NOT NULL COMMENT 'Email',
        bestellung.geburtsdatum = values['geburtsdatum'];//` date NOT NULL COMMENT 'Geburtsdatum',
        bestellung.kreditinstitut = values['kreditinstitut'];//` varchar(58) NOT NULL COMMENT 'Kreditinstitut',
        bestellung.bic = values['bic'];//` varchar(11) NOT NULL COMMENT 'BIC',
        bestellung.iban = values['iban'];//` varchar(35) NOT NULL COMMENT 'IBAN',
        bestellung.kontoinhaber = values['kontoinhaber'];//` varchar(70) NOT NULL COMMENT 'Kontoinhaber',
        //bestellung.abschlussdatum = "";//` AUTOMATISCH datetime NOT NULL DEFAULT CURRENT_TIMESTAMP COMMENT 'Abschlußdatum',
        bestellung.kndnr_erstkarte = ""
        if (mode == 2) { bestellung.kndnr_erstkarte = values['kundennummerbestehendengruenekarte'] };    //` varchar(8) NOT NULL COMMENT 'Pflicht bei Zweitkarte',

        bestellung.kundenkarte = "";  //TODO----------------------------Muss noch geklärt werden!!!!!!!!!!!!!!!!!!!!!!!!!!!!


        bestellung.mail_versendet = ""; //Wird vom Rest-Server gesetzt
        bestellung.mail_bestaetigt = "";
        bestellung.secureid = this.getSecureId();
        bestellung.id = null;
        bestellung.mein_auto_strom = autostrom ? "1" : "";



        let json = JSON.parse(JSON.stringify(bestellung));

        //delete json["id"]; //die id wird von der datenbank eingetragen
        delete json["abschlussdatum"]; //abschlussdatum wird von der datenbank eingetragen



        // let param = "?operation=insert&table=bestellungen";
        let param = "?operation=savebestellung";

        let p1 = '';
        let p2 = '';
        if (mode == 1) {
            p1 = json['kdnr_strom'];
            p2 = json['kdnr_gas_waerme']
        }

        if (mode == 2) {
            p1 = values['kundennummerbestehendengruenekarte'];
            p2 = values['kartennummerbestehendengruenekarte']
        }



        return this.getVorhandeneKarten(p1, p2, mode).pipe(mergeMap(
            res => {
                if (mode == 1) {
                    var a = [];
                    a = res;
                    if (a.length > 0)
                        json['kundenkarte'] = a.toString()
                }
                if (mode == 2) {
                    if (res.length > 0) {
                        json['kdnr_strom'] = res[0]['vertragskonto_strom'];
                        json['kdnr_gas_waerme'] = res[0]['vertragskonto_gas_waerme'];
                    }
                }
                return this.http.post(this.config.url + param, json);
            }
        ));
    }


    activateBestellung(id, secureid): Observable<any> {
        //Aktiviert eine Bestellung 
        let param = "?operation=activatebestellung";
        let j = '{"id":' + id + ',"secureid":"' + secureid + '"}';
        let json = JSON.parse(JSON.stringify(j));

        return this.http.post(this.config.url + param, json)


    }


    checkActive(id, secureid): Observable<any> {
        //Prüft, ob eine Bestellung schon aktiviert wurde
        let param = "?operation=checkactive";
        let j = '{"id":' + id + ',"secureid":"' + secureid + '"}';
        let json = JSON.parse(JSON.stringify(j));
        return this.http.post(this.config.url + param, json)
    }









}


/*

{
    "_mode": "3",
    "id": 0,
    "kundennummerstrom": "",
    "kundennummerwaerme": "",
    "kundennummerbestehendengruenekarte": "",
    "kartennummerbestehendengruenekarte": "",
    "anrede": "Herr",
    "titel": "",
    "firma": "",
    "ansprechpartner": "",
    "vorname": "Willi",
    "name": "Kunz",
    "namenszusatz": "",
    "plz": "88471",
    "ort": "Laupheim",
    "strasse": "Waldweg",
    "hausnummer": "",
    "hausnummerzusatz": "",
    "telefonnummer": "",
    "handynummer": "",
    "email": "r@r.de",
    "_email": "r@r.de",
    "geburtsdatum": "2000-01-01",
    "kontoinhaber": "Willi Kunz",
    "iban": "DE89370400440532013000",
    "bic": "COBADEFFXXX",
    "kreditinstitut": "Commerzbank 50447 Köln",
    "agb": true,
    "opt_in_Newsletter": 0,
    "opt_in_MaFo": 0
}

*/