import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { DbconfigService } from 'src/app/common/dbconfig.service';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(private config: DbconfigService, private http: HttpClient) {

  }





  checkout(values): Observable<any> {


    let kartennummer = values['kartennummer'];
    let haltestelle = values['haltestelle'];
    let linie = values['linie'];
    let datum = values['datum'];


    datum = moment(datum, 'YYYY-MM-DD').format('DD.MM.YYYY')

    let zeit = values['zeit'];


    let email = this.config.configuration['emailadresse_checkout'];
    if (!email) {
      email = 'ecard@bodo.de'
    }

    let json = JSON.stringify(JSON.parse('{"kartennummer":"' + kartennummer + '","haltestelle":"' + haltestelle + '","linie":"' + linie + '","datum":"' + datum + '","zeit":"' + zeit + '","email":"' + email + '"  }'));


    let param = "?operation=checkout";
    return this.http.post<any>(this.config.url + param, json).pipe(
      map(
        res => {
          
          return res;
        })
    )

  }






}




































