import { TextInputComponent } from './textinput';


import { RadioComponent } from './radio';
import { CheckBoxComponent } from './checkbox';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DropDownComponent } from './dropdown';
import { TextBoxComponent } from './textbox';
import { ControlRequiredPipe } from '../common/pipe-controllrequired';
import { DropDownLookupComponent } from './dropdownlookup';





@NgModule({
  declarations: [DropDownComponent,DropDownLookupComponent, CheckBoxComponent, RadioComponent, TextBoxComponent,TextInputComponent,ControlRequiredPipe],
  imports: [
    CommonModule,
    ReactiveFormsModule,

  ],
  providers:[
    ControlRequiredPipe
  ],
  exports:[
    DropDownComponent,
    DropDownLookupComponent,
    TextBoxComponent,
    TextInputComponent
  ]
})
export class CtrlsModule { }
