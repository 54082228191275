export class Kunde {
    id: number = 0;
    kundennummerstrom: string = "";
    kundennummerwaerme: string = "";
    kundennummerbestehendengruenekarte: string = "";
    kartennummerbestehendengruenekarte: string = "";


    anrede: string = "";
    titel: string = "";
    firma: string = "";
    ansprechpartner: string = "";
    vorname: string = "";
    name: string = "";
    namenszusatz: string = "";
    plz: string = "";
    ort: string = "";
    strasse: string = "";
    hausnummer: string = "";
    hausnummerzusatz: string = "";
    telefonnummer: string = "";
    handynummer: string = "";
    email: string = "";
    _email: string = ""; //email wiederholung

    geburtsdatum: string = "";
    kontoinhaber: string = "";
    iban: string = "";
    bic: string = "";
    kreditinstitut: string = "";
    agb: any = 0;//WICHTIG: "any"
    opt_in_Newsletter: any = 0;//WICHTIG: "any"
    opt_in_MaFo: any = 0;//WICHTIG: "any"

}