import { DbconfigService } from './../../common/dbconfig.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class VerlustmeldungService {

  constructor(
    private config: DbconfigService,
    private http: HttpClient) { }



  checkKundeNummer(kundennr): Observable<any> {

    // 10003411 = Grüne Karte UND Phys Karte (aktiv) ???????????????????????
    //12066737  = Grüne Karte inaktiv : hat beides "grüne Karte INAKTIV" und "Phys. Karte Aktiv"

    //00101149= Phys. Karte 
    //00101151= Phys. Karte inaktiv

    // let param = "?operation=select";
    // let sql = 'Select id,kartentyp from ver_traege where status="aktiv" and knd_nr1="' + kundennr + '"';

    // DIese Abfrage enthält alle gültigen GRÜNEN KARTEN:  Select k.*,v.* from kundenkarten k join vertraege v on k.kundennummer=v.knd_nr1

    let param = "?operation=kartentyp";
    let json = '{"kundennr":"' + kundennr + '"}';
    let sql = JSON.stringify(JSON.parse(json));

    return this.http.post<any>(this.config.url + param, sql).pipe(
      map(
        res => {
          let result = { 'kartenart': '' };
          if (!res['data'][0]) {
            return result;
          }
          if (res['data'][0]['kartentyp'] == 'Physikalisch') {
            result = { 'kartenart': 'physikalisch' };
          }

          if (res['data'][0]['kartentyp'] == 'Grüne Karte') {
            result = { 'kartenart': 'gruenekarte' };
          }


          return result;
        }
      )
    )




  }







  verlustPhsys(kundennummer, karte): Observable<any> {
    //karte : "kundenkarte" oder "GRÜNE KARTE"
    let email = this.config.configuration['emailadresse_verlustmeldung'];



    if (!email) {
      email = 'kundenkarte@swee.de'//////////////////////////////////////////////////////////////////////TODO # entfernen ///////////////////////////
    }
    let json = JSON.stringify(JSON.parse('{"email":"' + email + '","kundennummer":"' + kundennummer + '","karte":"' + karte + '"}'));
    let param = "?operation=verlustphys";
    return this.http.post<any>(this.config.url + param, json).pipe(
      map(
        res => {
          return res;
        })
    )

  }



  verlustgk(kundennummer, email): Observable<any> {
    //karte : "kundenkarte" oder "GRÜNE KARTE"

    let json = JSON.stringify(JSON.parse('{"email":"' + email + '","kundennummer":"' + kundennummer + '"}'));
    let param = "?operation=verlustgk";
    return this.http.post<any>(this.config.url + param, json).pipe(
      map(
        res => {
          return res;
        })
    )

  }





  checkEmail(email, kundennummer): Observable<any> {


    //let param = "?operation=select";
    //let sql = 'Select * from kunden_karten where kundennummer="' + kundennummer + '" and email="' + email + '"';


    let param = "?operation=cmail";
    let json = '{"kundennummer":"' + kundennummer + '","email":"' + email + '"}';
    let sql = JSON.stringify(JSON.parse(json));



    return this.http.post<any>(this.config.url + param, sql).pipe(
      map(
        res => res['data'][0])
    )

  }




}

