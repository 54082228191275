import { ModalformComponent } from './modalform/modalform.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [ModalformComponent],
  imports: [
    CommonModule,

  ],
  exports:[
    ModalformComponent
  ]

})
export class SharedModule { }
