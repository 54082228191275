import { DbconfigService } from './../../common/dbconfig.service';
import { Subscription } from 'rxjs';
import { GeonamesService } from './../../common/geonames.service';
import { IBanValidator } from 'src/app/common/ibanvalidator';
import { KdnrGwsValidator } from 'src/app/common/kdnrgwsvalidator';
import { GkarteService } from './../gkarte.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CustomValidators } from 'src/app/common/custom-validators';
import { ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Kunde } from '../Kunde';
import { KartennrValidator } from 'src/app/common/gkartevalidator';
import { Title } from '@angular/platform-browser';





@Component({
    selector: 'bestellung',
    templateUrl: './bestellung.component.html',
    styleUrls: ['./bestellung.component.scss']
})

export class BestellungComponent implements OnInit {

    modalopen: boolean = false;
    modaltitle: string = '';
    modalmessage: string = '';
    modaltype: string = 'info';
    modalshowcontinue: boolean = false;
    modal_closebtn_visible: boolean = true;
    onModalclose: (result: any) => void;



    step: number = 1 //step 1 : Der Bestellvorgang  step 2 : Die Zusammenfassung   step 3 : Email wurde versendet

    kunde = new Kunde;//Der aktuelle Kunde
    layout = 2;
    _mode: number = null
    _autostrom: boolean = false


    hasStromSubscription: Subscription;
    hasWaermeSubscription: Subscription;
    checkKartenArtSubscription: Subscription;
    absendenSubscription: Subscription;


    cities = [];
    streets = [];

    ErrorMsg = '';

    modefields = [ //Gibt an, welche Felder zu einem bestimmten Modus gehören
        [],
        ['kundennummerstrom', 'kundennummerwaerme'],
        ['kundennummerbestehendengruenekarte', 'kartennummerbestehendengruenekarte'],
        [],
    ]





    //Hilfsvariablen
    mailHasFocus = false;
    kontoinhaberFocus = false;





    constructor(
        private iBanValidator: IBanValidator,
        private kdnrGwsValidator: KdnrGwsValidator,
        private gkarteService: GkarteService,
        private kartennrValidator: KartennrValidator,
        private geonamesService: GeonamesService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private titleService: Title,
        private router: Router,
        private conf: DbconfigService) { }


    Form = this.formBuilder.group({
        _mode: [0],
        _autostrom: [false],
        id: [0],

        kundennummerstrom: [''],

        kundennummerwaerme: [''],
        kundennummerbestehendengruenekarte: [''],
        kartennummerbestehendengruenekarte: [''],
        anrede: [''],
        titel: [''],
        firma: [''],
        ansprechpartner: [''],
        vorname: [''],
        name: [''],
        namenszusatz: [''],
        plz: [''],
        ort: [''],
        strasse: [''],
        hausnummer: [''],
        hausnummerzusatz: [''],
        telefonnummer: [''],
        handynummer: [''],
        email: ['', [CustomValidators.emailValidator]],
        _email: [''],
        geburtsdatum: [null, [Validators.required, CustomValidators.dateTimeValidatorStd]], //werden im Code neu gesetzt setAllValidators()
        kontoinhaber: [''],
        iban: ['', { validators: null, updateOn: 'blur' }],//Validators werden dynmaisch gesetzt siehe "setAllValidators()"
        bic: [''],
        kreditinstitut: [''],
        agb: [false],
        autostromagb: [false],
        opt_in_Newsletter: [false],
        opt_in_MaFo: [false],
    },
        // ,{validators: [CustomValidators.emailMatchValidator, CustomValidators.kdnrValidator]} //--->werden im Code  gesetzt modeChange()
    );

    image = { "url": this.conf.configuration['urlfiles'] + "/files/stoerer/stoerer.png" };
    a = console.log("pouihwafceewaijwaeojzewaoczrcw", this.image);



    ngOnInit(): void {
        this.titleService.setTitle('STADTWERK AM SEE-Grüne Karte');
        this.kunde = new Kunde;
        this.mode = 3;//Der Defaultmodus (Radio-Button), hier werden implizit alle CrossField-validatoren gesetzt
        this.autostrom = false;

        //Wenn über die url eine Parameter ( ...../bestellung/1) aufgerufen wird, dann entsprechend setzen
        this.route.params.subscribe(params => {
            if (params['mode']) {
                if (['1', '2', '3'].includes(params['mode'])) //erlaubt Modus im Bereich 1..3 
                    this.mode = params['mode'];
            }
        }
        )



        this.initForm(this.kunde);
        this.Form.patchValue({ _mode: '' + this.mode })


        //strom : 00100009
        //gas : 00100355
        /******************************************************* */


        /*
        this.Form.patchValue({ iban: 'DE89370400440532013000' })
        this.Form.patchValue({ bic: 'COBADEFFXXX' })
        this.Form.patchValue({ kreditinstitut: 'Commerzbank 50447 Köln' })
    
    
        this.Form.patchValue({ agb: true })
        this.Form.patchValue({ email: 'rolf.schettler@konzeptdata.de' })
        this.Form.patchValue({ _email: 'rolf.schettler@konzeptdata.de' })
        this.Form.patchValue({ geburtsdatum: '2000-01-01' })
    
    
        this.Form.patchValue({ anrede: 'Herr' })
        this.Form.patchValue({ name: 'Mustermann' })
        this.Form.patchValue({ vorname: 'Heinrich' })
        this.Form.patchValue({ kontoinhaber: 'Heinrich Mustermann' })
        this.Form.patchValue({ plz: '8848' })
        this.Form.patchValue({ strasse: 'Waldweg' })
    
    
        this.Form.patchValue({ hausnummer: '3' })
        */
        /******************************************************* */


        //Email nur in Kleinbuchstaben
        this.Form.get('email').valueChanges.subscribe(event => {
            if (event)
                this.Form.get('email').setValue(event.toLowerCase(), { emitEvent: false });

        });

        this.Form.get('_email').valueChanges.subscribe(event => {
            if (event)
                this.Form.get('_email').setValue(event.toLowerCase(), { emitEvent: false });
        });

        //Nur Grossbuchstaben (after blur!!)
        this.Form.get('iban').valueChanges.subscribe(event => {
            if (event)
                this.Form.get('iban').setValue(event.toUpperCase(), { emitEvent: false });
        });

        //Nur Grossbuchstaben
        this.Form.get('bic').valueChanges.subscribe(event => {
            if (event)
                this.Form.get('bic').setValue(event.toUpperCase(), { emitEvent: false });
        });


    }


    initForm(kunde: Kunde) {
        //Werte vom Objekt in die Form übertragen.
        for (var key in kunde) {
            this.Form.patchValue({ [key]: kunde[key] })
        }
        this.setAllValidators();//Alle Feldvalidatoren setzen
    }

    /****************************************   Validieren und Speichern   ************************************************ */
    speichern() {
        if (!this.Form.get('agb').value) {
            this.ErrorMsg = 'Bitte bestätigen Sie die AGB'
            return
        }
        if (this.autostrom && !this.Form.get('autostromagb').value) {
            this.ErrorMsg = 'Bitte bestätigen Sie die AGB für MEIN AUTO STROM'
            return
        }
        this.Form.markAllAsTouched();
        this.Form.updateValueAndValidity();
        this.ErrorMsg = '';

        let cnt = 0;
        if (this.Form.invalid) {

            this.ErrorMsg = 'Die Eingaben sind nicht korrekt. Bitte überprüfen Sie das Formular '
            for (var field in this.Form.controls) {

                cnt = cnt + 1;
                if (this.Form.controls[field].errors) {

                    if (this.Form.controls[field].errors['required']) {
                        this.ErrorMsg = this.ErrorMsg + ': Pflichteingabe fehlt '
                        console.log(this.Form.controls[field], cnt);//DIESES LOG NICHT ENTFERNEN
                        break;
                    }
                    if (this.Form.controls[field].errors['NoKdnrSelected']) {
                        this.ErrorMsg = this.ErrorMsg + ': Strom / Erdgas / Wärme, es muss mind 1 Kundennummer angegeben werden '
                        break;
                    }
                    if (this.Form.controls[field].errors['NoEmailMatch']) {
                        this.ErrorMsg = this.ErrorMsg + ': Die Email-Adresse wurde nicht korrekt wiederholt '
                        break;
                    }
                    if (this.Form.controls[field].errors['InvalidIban']) {
                        this.ErrorMsg = this.ErrorMsg + ': Die IBAN-Nummer ist ungültig '
                        break;
                    }


                    if (this.Form.controls[field].errors['invalidEmail']) {
                        this.ErrorMsg = this.ErrorMsg + ': Die Email-Adresse ist ungültig '
                        break;
                    }
                }
            }
            window.scroll(0, 0);//Nach Oben Scrollen
            return;
        }
        //Ab hier folgt die Übertragung :

        if (!this.validationPending()) {
            //this.openmodal('Bestellung erfolgreich', 'Ihre Bestellung wurde erfolgreich abgeschlossen.', this.gotoZusammenfassung, 'info', false)
            this.step = 2;
            window.scroll(0, 0);//Nach Oben Scrollen
        }




    }


    validationPending() {
        //Überwacht, ob noch eine Validierung eines Feldes aktiv ist (ggf. SaveButton Inactive)
        let pending = (this.hasStromSubscription && !this.hasStromSubscription.closed);

        pending = pending || (this.hasWaermeSubscription && !this.hasWaermeSubscription.closed);
        pending = pending || (this.checkKartenArtSubscription && !this.checkKartenArtSubscription.closed);
        pending = pending || (this.absendenSubscription && !this.absendenSubscription.closed);
        pending = pending
            || this.Form.get('iban').pending
            || this.Form.get('kundennummerstrom').pending
            || this.Form.get('kundennummerwaerme').pending
            || this.Form.get('kundennummerbestehendengruenekarte').pending
            || this.Form.get('kartennummerbestehendengruenekarte').pending



        return pending
    }

    closeAll(result: any) {
        //Schließt den Vorgang und initialisiert das Formular mit einem leeren Datensatz

        this.Form.reset();
        this.Form.markAsUntouched;
        this.ngOnInit();

    }


    test(result: any) {
        //
    }


    cancelAll(result: any) {

    }

    gotoZusammenfassung(result: any) {
        this.step = 2;
        window.scroll(0, 0);//Nach Oben Scrollen
    }

    absenden() {

        this.absendenSubscription = this.gkarteService.saveBestellung(this.Form.value, this.mode, this.autostrom).subscribe(
            res => {
                if (res.status == 'OK') {
                    this.step = 3;
                    window.scroll(0, 0);//Nach Oben Scrollen
                } else {
                    this.ErrorMsg = 'Es ist ein Fehler beim Absenden aufgetreten. Versuchen Sie es bitte später erneut.'
                }
            },
            err => {
                this.ErrorMsg = err;
            }
        )

    }

    gotoZweitkarte(result: any) {
        if (result == 'continue') {
            this.mode = 2;
        }


    }

    gotoBasiskarte(result: any) {
        if (result == 'continue') {
            this.mode = 3;
        }

    }


    openmodal(
        //Öffnet das modale Fenster
        _title: string,
        _message: string,
        onclose: (result: any) => void,
        _type: string = 'info',
        _modalshowcontinue: boolean = false,
        _modal_closebtn_visible: boolean = true
    ) {
        this.modalopen = true;
        this.modalmessage = _message;
        this.modaltitle = _title;
        this.modaltype = _type;
        this.modalshowcontinue = _modalshowcontinue;
        this.modal_closebtn_visible = _modal_closebtn_visible;
        this.onModalclose = onclose;
    }

    /****************************************Behandlung von Änderungen der Felder im Formular************************************************ */


    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;

    }

    onChange(event) {

        this.ErrorMsg = '';
    }



    checkStateAndType(vertraege) {

        let a_ph = false;
        let a_gk = false;
        if (vertraege.data.length == 0) {
            return false;
        } else {

            // 1. Prüfen, ob Vertrag noch nicht aktiv (Spätestes Datum steht immer am Anfang)
            if (moment() < moment(vertraege.data[0]['lieferbeginn'], 'YYYY-MM-DD')) {
                return 'noch-nicht-aktiv';
            }

            //2. Prüfen, ob Karte ="Physikalisch" und "aktiv"
            if (vertraege.data.find(karte => karte.status == "aktiv" && karte.kartentyp == "Physikalisch")) {
                a_ph = true;

            }

            //2. Prüfen, ob Karte ="Grüne Karte" und "aktiv"
            if (vertraege.data.find(karte => karte.status == "aktiv" && karte.kartentyp == "Grüne Karte")) {
                a_gk = true;

            }

            if (a_ph && a_gk) {
                //Beide Karten sind aktiv
                return 'beide-aktiv'; //(Zweitkarte bestelllen)
            }

            if (a_ph) {
                return 'aktiv-physikalisch'; // (Kundenkarte wird gesperrt bei Bestellung)
            }

            if (a_gk) {
                return 'aktiv-gruene-karte'; //(Zweitkarte bestelllen)
            }

            if (!(a_ph || a_gk)) {
                //Keine aktive Karten vorhanden
                return 'keine-aktiv'; //	"GRÜNE KARTE Energie und Energie plus" kann bestellt werden
            }

            return "FEHLER"
        }


    }


    onChangeKundenNummer(id) {
        this.ErrorMsg = '';

        let _kundennummerstrom = this.Form.get('kundennummerstrom').value;
        let _kundennummerwaerme = this.Form.get('kundennummerwaerme').value;

        //if ((_kundennummerstrom !== "") || (_kundennummerwaerme !== "")) {
        if (_kundennummerstrom || _kundennummerwaerme) {
            this.checkKartenArtSubscription = this.gkarteService.checkKartenArt(_kundennummerstrom, _kundennummerwaerme).subscribe(
                vertraege => {

                    let result = this.checkStateAndType(vertraege)
                    //..
                    if (result == "noch-nicht-aktiv") {
                        this.openmodal('Hinweis', 'Ihr Energievertrag ist noch nicht aktiv. Sie können vorerst eine GRÜNE KARTE mit Jahresgebühr bestellen. Bitte lassen Sie Ihre Rabattierung beim Kundenservice nachbuchen, sobald Sie Ihre Energie von uns beziehen.', this.gotoBasiskarte, 'info', true, false);
                    }
                    //..
                    if ((result == "beide-aktiv") || (result == "aktiv-gruene-karte")) {
                        this.openmodal('Hinweis', 'Sie besitzen bereits eine GRÜNE KARTE. Sie möchten eine Zweitkarte bestellen? Bitte klicken Sie „Fortsetzen“ ', this.gotoZweitkarte, 'info', true);
                        //??????????????????????????????????????

                    }
                    //..
                    if (result == "aktiv-physikalisch") {
                        this.openmodal('Hinweis', 'Sie besitzen bereits eine Kundenkarte des STADTWERKS AM SEE. Bitte beachten Sie, dass Ihre Kundenkarte gesperrt wird, sobald Ihre GRÜNE KARTE produziert wurde.', this.test, 'warning', false, true);
                        //Warum wird dieser Hinweis nicht auch oben angezeigt ? Die Phys. Karte ist ja auch dort aktiv! ????????????????????????????
                    }
                    //..
                    if (result == "aktiv-gruene-karte") {

                    }
                    //..
                    if (result == "keine-aktiv") {
                        //Was passiert, wenn KEINE Karte aktive ist ?

                    }
                    //..
                    if (result == "FHELER") {

                    }


                }
            )
        }
        /*
            */
    }

    onChangePlz(event) {
        this.ErrorMsg = '';
        let plz = event.target.value;
        this.cities = [];
        this.streets = [];
        this.Form.get('strasse').setValue('');

        this.geonamesService.getCities('DE', plz).subscribe(
            data => {
                this.cities = data;
                if (this.cities.length == 1) {
                    this.Form.get('ort').setValue(this.cities[0]['city']);
                }
                else {
                    this.Form.get('ort').setValue(null);
                }
                this.getStreets(plz);
            }
        );
    }


    getStreets(plz) {
        //ACHTUNG : Funktion in "geonamesService.getStreets" muss aktiviert werden!
        this.geonamesService.getStreets(plz).subscribe(
            data => {
                this.streets = data;

            }
        );


    }





    onFocus(event) {
        let id = event.target.id;
        switch (id) {
            case "email":
                this.mailHasFocus = true;
                break;
            case "kontoinhaber":
                this.kontoinhaberFocus = true;
                if (this.Form.get('kontoinhaber').value.trim() == '') {
                    if (this.Form.get('anrede').value == 'Firma')
                        this.Form.get('kontoinhaber').setValue(this.Form.get('firma').value)
                    else
                        this.Form.get('kontoinhaber').setValue((this.Form.get('vorname').value + ' ' + this.Form.get('name').value).trim())
                }
                break;
            default:

        }
    }


    onBlur(event) {
        let id = event.target.id;
        switch (id) {
            case "email":
                this.mailHasFocus = false;
                break;
            case "kontoinhaber":
                this.kontoinhaberFocus = false;
                break;

            default:

        }
    }



    anredeChange(event) {
        this.setAllValidators()
        this.ErrorMsg = '';
    }






    /****************************************  Setzen von Validatoren  ************************************************ */




    setAllValidators() {



        let anredefields = {//Gibt an, welche PflichtFelder zu einer bestimmten Anrede gehören
            all: ['anrede', 'name', 'vorname', 'plz', 'ort', 'strasse', 'hausnummer', '_email', 'email', 'geburtsdatum', 'iban', 'kontoinhaber'],
            firma: ['anrede', 'firma', 'plz', 'ort', 'strasse', 'hausnummer', '_email', 'email', 'iban', 'kontoinhaber']
        }

        for (var field in this.Form.controls) {
            //Alle Validierungen rücksetzen  
            this.Form.controls[field].setValidators(null);
            this.Form.controls[field].setAsyncValidators(null);
            //Alle bestehenden Fehler rücksetzen
            this.Form.controls[field].setErrors(null);
        }

        let anrede = this.Form.get('anrede').value;
        switch (anrede) {
            case "Firma":
                for (let field of anredefields['firma']) {
                    this.Form.get(field).setValidators([Validators.required]);
                }
                //Alle CUSTOM-Validators setzen
                this.Form.get('iban').setAsyncValidators(this.iBanValidator.validate.bind(this.iBanValidator))  // updateOn: 'blur' ist bereits gesetzt!
                this.Form.get('email').setValidators([Validators.required, CustomValidators.emailValidator]);
                break;

            default:
                for (let field of anredefields['all']) {
                    this.Form.get(field).setValidators([Validators.required]);
                }

                //Alle CUSTOM-Validators setzen
                this.Form.get('geburtsdatum').setValidators([Validators.required, CustomValidators.dateTimeValidatorStd]);
                this.Form.get('iban').setAsyncValidators(this.iBanValidator.validate.bind(this.iBanValidator))  // updateOn: 'blur' ist bereits gesetzt!
                this.Form.get('email').setValidators([Validators.required, CustomValidators.emailValidator]);
        }


        if (this.mode == 1) {
            this.Form.get('kundennummerstrom').setAsyncValidators(this.kdnrGwsValidator.validate.bind(this.kdnrGwsValidator))
            this.Form.get('kundennummerwaerme').setAsyncValidators(this.kdnrGwsValidator.validate.bind(this.kdnrGwsValidator))
            this.Form.setValidators([CustomValidators.kdnrValidator, CustomValidators.emailMatchValidator])
        }



        if (this.mode == 2) {
            this.Form.get('kundennummerbestehendengruenekarte').setAsyncValidators(this.kartennrValidator.validate.bind(this.kartennrValidator))
            this.Form.get('kartennummerbestehendengruenekarte').setAsyncValidators(this.kartennrValidator.validate.bind(this.kartennrValidator))
            this.Form.get('kundennummerbestehendengruenekarte').setValidators([Validators.required]);
            this.Form.get('kartennummerbestehendengruenekarte').setValidators([Validators.required]);
            this.Form.setValidators([CustomValidators.emailMatchValidator])
        }

        if (this.mode == 3) {
            this.Form.setValidators([CustomValidators.emailMatchValidator])
        }

        for (var field in this.Form.controls) {
            //Alle Validierungen ausführen
            this.Form.controls[field].updateValueAndValidity();

        }



    }



    /****************************************  Setzen des Formularmodus (siehe RADIO-Buttons) "mode GETTER/SETTER"  ************************************************ */

    modeChange(mode: number) {
        this.mode = mode;
    }

    set mode(value) {
        if (this._mode != value) {
            this.Form.get('_mode').setValue('' + value)
            this._mode = value
            this.Form.get('kundennummerbestehendengruenekarte').setValue('');
            this.Form.get('kartennummerbestehendengruenekarte').setValue('');
            this.Form.get('kundennummerstrom').setValue('');
            this.Form.get('kundennummerwaerme').setValue('');

            this.Form.setValidators([]);//Alle CrossField-Validatoren rücksetzen
            this.setAllValidators();//Alle Validatoren neu setzen
            this.ErrorMsg = '';
            this.Form.updateValueAndValidity();
            this.Form.markAsUntouched();

        }
    }

    get mode(): number {
        return this._mode;
    }

    autostromChange(autostrom: boolean) {
        this.autostrom = autostrom;
    }

    set autostrom(value) {
        if (this._autostrom != value) {
            this.Form.get('_autostrom').setValue('' + value)
            this._autostrom = value

            this.Form.setValidators([]);//Alle CrossField-Validatoren rücksetzen
            this.setAllValidators();//Alle Validatoren neu setzen
            this.ErrorMsg = '';
            this.Form.updateValueAndValidity();
            this.Form.markAsUntouched();

        }
    }

    get autostrom(): boolean {
        return this._autostrom;
    }


}
