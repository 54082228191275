<!-- Button trigger modal -->
<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="modalopenbtn" style="display: none;"></button>
<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header" [ngClass]="{'bg-danger': type === 'warning', 'bg-info' : type === 'info', 'bg-success' : type === 'success' }" style="color:white">
                <h5 class="modal-title" id="staticBackdropLabel">{{title}}</h5>
                <button *ngIf="closebtn_visible" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Beenden" (click)="close('cancel')"></button>
            </div>
            <div class="modal-body">
                {{message}}
                
            </div>
            <div class="modal-footer">

                <button *ngIf="showcontinue==true" type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="close('continue')">Fortsetzen</button>
                <button *ngIf="closebtn_visible"   type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="close('close')">Schließen</button>
            </div>
        </div>
    </div>
</div>