
export class User {
  id: number = 0;
  username: string = "";
  vorname: string = "";
  nachname: string = "";
  password: string = "";
  email: string = "";
  administrator: string = "";
  verwaltung: string = "";
  aussendienst: string = "";
  gast: string = "";
  erstellungsdatum: string = "";



  constructor() {
    this.id = 0;
    this.username = "";
    this.vorname = "";
    this.nachname = "";
    this.password = "";
    this.email = "";
    this.administrator = "";
    this.verwaltung = "";
    this.aussendienst = "";
    this.gast = "";
    this.erstellungsdatum = "";

  }

}


