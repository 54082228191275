<pageheader></pageheader>



<div *ngIf="step==-1" class="container-md we_verlustmeldung">
    <h1 class="we_header">Verlustmeldung KARTE</h1>
    <div style="max-width: 770px">

        <div class="row g-3 mb-2">
            <div class="col-sm-12 we_subheader">
                Welche Karte haben Sie verloren? Bitte wählen Sie.
            </div>

        </div>

        <div class="row g-3">
            <div class="col">
                <img class="imgkarte karte we_karte" src="assets\img\weissekarte.svg"  alt="grüne Karten" (click)="weissekarteClick()">
            </div>
            <div class="col">
                <img class="imgkarte karte we_karte" src="assets\img\gruenekarte.svg"  alt="grüne Karten" (click)="gruenekarteClick()">


            </div>

        </div>
    </div>
</div>


<div *ngIf="step==0" class="container-md we_verlustmeldung">
    <div style="max-width: 770px">

        <div class="row mb-2">
            <div class="col-sm-12 we_subheader">
                Möchten Sie die neue GRÜNE KARTE mit noch mehr <a class="we_link" href="https://www.gruenekarte.de/" target="_blank">Vorteilen</a> bestellen?
            </div>

        </div>

        <div class="row g-3">
            <div class="col-sm-6">
                <button type="button" (click)="weiterzurBestellungGrueneKarte()" class="btn btn-primary we_button fullwidth_button" data-bs-toggle="tooltip" data-bs-placement="top" title="Ich möchte die neue GRÜNE KARTE bestellen">
                    JA</button>
            </div>
            <div class="col-sm-6">
                <button type="button" (click)="VerlustmeldungAlteKarte()" class="btn btn-primary we_button fullwidth_button" data-bs-toggle="tooltip" data-bs-placement="top" title="Ich möchte zur Verlustmeldung weitergeleitet werden">
                    NEIN</button>
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-sm-12 mt-2">
                <span style="color:#37aa4b;">Hinweis: Durch die Bestellung der GRÜNEN KARTE wird Ihre alte Kundenkarte automatisch gesperrt.</span>
            </div>

        </div>



    </div>
</div>

<div *ngIf="step==1" class="container-md we_verlustmeldung">
    <form autocomplete="off" class="row g-3 we_form" [formGroup]="Form">
        <h1 class="we_header">Verlustmeldung GRÜNE KARTE</h1>

        <div class="col-md-6">
            <textinput [readonly]="kartenart!==''" [layout]="layout" [field]="{name:'kundennummer',label:'Kundennummer',maxlength:8}" [form]="Form" (onChange)="onChange($event)"></textinput>
        </div>
        <div class="col-md-6">
        </div>

        <div class="col-md-6">
            <div class="text-dark border border-secondary p-2 we_inlinehelp">
                {{msgkundennummer}}
            </div>

        </div>
        <div class="col-md-6">
        </div>


        <div *ngIf="kartenart=='gruenekarte'">
            <div class="col-md-6">
                <textinput [layout]="layout" [field]="{name:'email',label:'E-Mail-Adresse',maxlength:70}" [form]="Form" (onChange)="onChange($event)" (onFocus)="onFocus($event)" (onBlur)="onBlur($event)"></textinput>
            </div>
            <div class="col-md-6">
            </div>


            <div class="col-md-6">
                <div class="text-dark border border-secondary p-2 we_inlinehelp">
                    {{msgemail}}
                </div>
            </div>
        </div>




        <div *ngIf="kartenart==''">
            <div class="col-md-6">
                <!--Senden-Button-->
                <button type="button" [disabled]="validationPending()" (click)="pruefen()" class="btn btn-primary we_button fullwidth_button" data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Überprüfung der eingegebenen Kundennummer">
                    Prüfen</button>
            </div>
        </div>





        <div *ngIf="kartenart=='physikalisch'">
            <div class="col-md-6">
                <!--Senden-Button-->
                <button type="button" [disabled]="validationPending()" (click)="verlustPhsys(Form.get('kundennummer').value, 'GRÜNE KARTE')" class="btn btn-primary we_button fullwidth_button" data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Wünschen Sie eine neue GRÜNE KARTE ?">
                    Jetzt GRÜNE KARTE bestellen</button>
            </div>
            <div class="col-md-6 my-1">

            </div>
            <div class="col-md-6">
                <!--Senden-Button-->
                <button type="button" [disabled]="validationPending()" (click)="verlustPhsys(Form.get('kundennummer').value,'Kundenkarte')" class="btn btn-primary we_button fullwidth_button" data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Möchten Sie Ihre Karte weiterhin nutzen ?">
                    Bisherige Karte weiter nutzen</button>
            </div>
        </div>


        <div *ngIf="kartenart=='gruenekarte'">
            <div class="col-md-6">
                <!--Senden-Button-->
                <button type="button" [disabled]="validationPending()" (click)="sperren()" class="btn btn-primary we_button fullwidth_button" data-bs-toggle="tooltip" data-bs-placement="top" title="GRÜNE KARTE sperren....">
                    Jetzt GRÜNE KARTE sperren</button>
                <div class="row" style="color:#37aa4b">
                    <div class="col-2" style="width:70px">Hinweis:</div>
                    <div class="col-10">Mit dem Absenden der Verlustmeldung wird Ihre GRÜNE KARTE gesperrt.<br>Für die Erstellung der Ersatzkarte werden Ihnen 10 Euro inkl. MwSt. berechnet.</div>

                </div>
            </div>
        </div>


        <div class="col-md-6" *ngIf="validationPending()">
            <!--Bootstrap-Spinner-->
            <div class="d-flex justify-content-center">
                <div class="d-flex align-items-center">
                    <div class="spinner-border ms-auto p-4" role="status" aria-hidden="true"></div>
                    <strong class="ms-4"> Prüfen.... </strong>
                </div>

            </div>
        </div>



    </form>
</div>



<div *ngIf="step==2" class="container-md we_verlustmeldung">

    <div class="row g-3">
        <h3 class="we_header">Ihre Verlustmeldung wurde erfolgreich übermittelt!</h3>
        <!--h5 class="we_subheader">Ihre Bestellung wurde erfolgreich gesendet.</h5 -->
    </div>


</div>



<div *ngIf="step==3" class="container-md we_verlustmeldung">

    <div class="row g-3">
        <h3 class="we_header">Ihre Verlustmeldung wurde erfolgreich übermittelt!</h3>
        <p class="we_subheader">
            Ihre Verlustmeldung ist eingegangen und wird geprüft.
        </p>
    </div>


</div>



<div *ngIf="ErrorMsg!==''" class="position-fixed top-0  p-3 text-center bg-danger text-white" style="width:100%">{{ErrorMsg}}</div>





<pagefooter></pagefooter>