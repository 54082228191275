<pageheader></pageheader>
<div *ngIf="step==1" class="container-md we_bestellung">
    <form autocomplete="off" class="row g-3 we_form" [formGroup]="Form">
        <div class="col-md-6"></div>

        <div class="col-md-6">
            <img ng-show="image" class="we_stoerer" src="/assets/img/stoerer.png"
                onerror="this.parentElement.removeChild(this);" />
        </div>

        <div class="col-md-6">
            <h1 class="we_header">Bestellung GRÜNE KARTE</h1>
        </div>

        <div class="col-md-6"></div>

        <div class="col-md-6">
            <p class="we_subheader">Bitte wählen Sie den gewünschten Kartentyp aus:</p>

            <!--RADIO-BUTTONS-->
            <div class="form-check we_check">
                <input (change)="modeChange($event.target.value)" class="form-check-input we_radio" type="radio"
                    value="1" formControlName="_mode" id="flexRadioDefault1">
                <label class="form-check-label we_radio_label" for="flexRadioDefault1">
                    GRÜNE KARTE Energie und Energie plus
                </label>
                <div class="we_sublabel" [ngClass]="{'we_active': mode==1}">
                    für Kunden mit Energievertrag (0 Euro/Jahr)
                </div>
            </div>
            <div class="form-check we_check">
                <input (change)="modeChange($event.target.value)" class="form-check-input we_radio" type="radio"
                    value="2" formControlName="_mode" id="flexRadioDefault2">
                <label class="form-check-label we_radio_label" for="flexRadioDefault2">
                    GRÜNE KARTE HochZwei
                </label>
                <div class="we_sublabel" [ngClass]="{'we_active': mode==2}">
                    Zweitkarte für Kunden mit Energievertrag (7,50 Euro/Jahr)
                </div>
            </div>
            <div class="form-check we_check">
                <input (change)="modeChange($event.target.value)" class="form-check-input we_radio" type="radio"
                    value="3" formControlName="_mode" id="flexRadioDefault3">
                <label class="form-check-label we_radio_label" for="flexRadioDefault3">
                    GRÜNE KARTE Basis

                </label>
                <div class="we_sublabel" [ngClass]="{'we_active': mode==3}">
                    für Kunden ohne Energievertrag (15 Euro/Jahr)
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <p class="we_subheader">Bitte wählen Sie die gewünschte Zusatzleistung aus:</p>

            <div class="form-check">
                <input class="form-check-input we_checkbox" (change)="autostromChange($event.target.checked)"
                    type="checkbox" id="meinAutoStromCheckbox" />
                <div class="we_autostromlabel">
                    <label class="form-check-label we_checkbox_label" for="meinAutoStromCheckbox">MEIN AUTO
                        STROM</label>
                    <label class="we_horizontal_space"></label>
                    <a class="we_continue_reading"
                        href="https://www.stadtwerk-am-see.de/de/Wohnen/eMobilitaet/#autostrom" target="_blank">WEITER
                        LESEN &#8594;</a>
                    <div class="we_sublabel we_active" *ngIf="autostrom">
                        Grundpreis (1,95 EUR/Monat)<br />
                        Arbeitspreis (AC) (39,60 Ct/kWh)<br />
                        Arbeitspreis (DC) (49,60 Ct/kWh)
                    </div>
                </div>
            </div>
        </div>



        <ng-container *ngIf="mode==1">
            <div class="col-md-6">
                <textinput [layout]="layout" [field]="{name:'kundennummerstrom',label:'Kundennummer Strom',maxlength:8}"
                    [form]="Form" (onChange)="onChangeKundenNummer($event.target.id)"></textinput>
            </div>
            <div class="col-md-6">
                <textinput [layout]="layout"
                    [field]="{name:'kundennummerwaerme',label:'Kundennummer Gas / Wärme',maxlength:8}" [form]="Form"
                    (onChange)="onChangeKundenNummer($event.target.id)"></textinput>
            </div>

            <div class="col-md-12 ">
                <div class="bg-warning text-dark border border-secondary p-2 we_inlinehelp">
                    <b>Haben Sie weitere Verträge unter anderen Kundennummern bei uns?</b> Tragen Sie bitte alle
                    Kundennummern (Strom / Gas / Wärme) ein, um den maximalen Rabatt mit der GRÜNEN KARTE zu erhalten.
                    Sollten Sie mehrere Verträge
                    unter einer Kundennummer haben, <b>tragen Sie diese bitte</b> in beiden Feldern ein.
                </div>
            </div>





        </ng-container>

        <ng-container *ngIf="mode==2">
            <div class="col-md-6">
                <textinput [layout]="layout"
                    [field]="{name:'kundennummerbestehendengruenekarte',label:'Kundennummer der bestehenden GRÜNEN KARTE',maxlength:8}"
                    [form]="Form" (onChange)="onChange($event)"></textinput>
            </div>
            <div class="col-md-6">
                <!--textinput [layout]="layout" [field]="{name:'kartennummerbestehendengruenekarte',label:'Kartennummer der bestehenden GRÜNEN KARTE',maxlength:40}" [form]="Form" (onChange)="onChange($event)"></textinput -->


                <div class="form-floating">
                    <input (change)="onChange($event)" type="text" unmasked="true" angularFormsMask="DD.DDD.DDD"
                        class="form-control we_input_knr we_input"
                        placeholder="Kartennummer der bestehenden GRÜNEN KARTE" maxlength="40"
                        id="kartennummerbestehendengruenekarte" formControlName="kartennummerbestehendengruenekarte">
                    <label for="kartennummerbestehendengruenekarte" class="we_label">Kartennummer der bestehenden GRÜNEN
                        KARTE <strong>*</strong></label>
                    <label class="we_leftlabel_knr">6.291-</label>
                    <label class="we_rightlabel_knr">-x</label>
                    <div class="text-danger"
                        *ngIf="Form.get('kartennummerbestehendengruenekarte').hasError('required') && Form.get('kartennummerbestehendengruenekarte').touched">
                        Kartennummer der bestehenden GRÜNEN KARTE muss angegeben werden
                    </div>
                    <div class="text-danger"
                        *ngIf="Form.get('kartennummerbestehendengruenekarte').hasError('KartenNrerr') && Form.get('kartennummerbestehendengruenekarte').touched">
                        Kundennummer / Kartennummer passen nicht zueinander
                    </div>
                    <div class="text-danger"
                        *ngIf="Form.get('kartennummerbestehendengruenekarte').hasError('existingKartennrRequest') && Form.get('kartennummerbestehendengruenekarte').touched">
                        Für diese Kundennummer existiert bereits eine Anfrage
                    </div>

                </div>




            </div>
        </ng-container>


        <div class="col-md-4">
            <dropdown [layout]="layout"
                [field]="{name:'anrede',label:'Anrede',options:['','Herr','Frau','Familie','Firma','An']}" [form]="Form"
                (onChange)="anredeChange($event)"></dropdown>
        </div>



        <div class="col-md-4">
            <textinput *ngIf="Form.get('anrede').value!=='Firma'" [layout]="layout"
                [field]="{name:'titel',label:'Titel',maxlength:40}" [form]="Form" (onChange)="onChange($event)">
            </textinput>
        </div>
        <div class="col-md-4">
        </div>



        <ng-container *ngIf="Form.get('anrede').value=='Firma'">
            <div class="col-md-4">
                <textinput [layout]="layout" [field]="{name:'firma',label:'Firmenname',maxlength:50}" [form]="Form"
                    (onChange)="onChange($event)"></textinput>
            </div>

            <div class="col-md-8">
                <textinput [layout]="layout" [field]="{name:'ansprechpartner',label:'Ansprechpartner',maxlength:50}"
                    [form]="Form" (onChange)="onChange($event)"></textinput>
            </div>
        </ng-container>


        <ng-container *ngIf="Form.get('anrede').value!=='Firma'">

            <div class="col-md-4">
                <textinput [layout]="layout" [field]="{name:'vorname',label:'Vorname',maxlength:50}" [form]="Form"
                    (onChange)="onChange($event)"></textinput>
            </div>

            <div class="col-md-4">
                <textinput [autocomplete]="'new-unknownfield'" [layout]="layout"
                    [field]="{name:'name',label:'Name',maxlength:50}" [form]="Form" (onChange)="onChange($event)">
                </textinput>
            </div>


            <div class="col-md-4">
                <textinput [autocomplete]="'new-unknownfield'" [layout]="layout"
                    [field]="{name:'namenszusatz',label:'Namenszusatz',maxlength:50}" [form]="Form"
                    (onChange)="onChange($event)"></textinput>
            </div>


        </ng-container>



        <div class="col-md-4">
            <textinput [layout]="layout" [field]="{name:'plz',label:'PLZ',maxlength:10}" [form]="Form"
                (onChange)="onChangePlz($event)"></textinput>
        </div>


        <div class="col-md-8">

            <!--textinput [layout]="layout" [field]="{name:'ort',label:'Ort',maxlength:40}" [form]="Form" (onChange)="onChange($event)"></textinput -->
            <dropdownlookup [layout]="layout" key='city' displayvalue='city'
                [field]="{name:'ort',label:'Ort',options:cities}" [form]="Form" (onChange)="onChange($event)">
            </dropdownlookup>

        </div>



        <div class="col-md-6">
            <dropdown [layout]="layout" [field]="{name:'strasse',label:'Straße',options:streets}" [form]="Form"
                (onChange)="onChange($event)"></dropdown>
        </div>





        <!--https://www.npmjs.com/package/angular-ng-autocomplete#usage-sample -->
        <!--Folgender Block bietet ein Autocomplete für die Auswahl der Strasse (siehe "bestellung.component.ts" "getStreets()")-->
        <!--
        <div class="col-md-6">
            <div class="form-floating">
                <div class="form-control we_input" style="padding: 1.0rem 0 0 0">
                    <div class="ng-autocomplete">

                        <ng-autocomplete [data]="streets" searchKeyword="strasse" placeholder='Straße*' formControlName="strasse" [itemTemplate]="itemTemplate">
                        </ng-autocomplete>
                        <ng-template #itemTemplate let-item>
                            <a [innerHTML]="item"></a>
                        </ng-template>
                    </div>
                </div>
                <label style="z-index: 1000;" *ngIf="Form.value['strasse']!==''">Straße*</label>
                <div class="text-danger" *ngIf="Form.get('strasse').hasError('required') && Form.get('strasse').touched">
                    Straße muss angegeben werden
                </div>
            </div>
        </div>
        -->


        <div class="col-md-3">
            <textinput [layout]="layout" [field]="{name:'hausnummer',label:'Hausnummer',maxlength:4}" [form]="Form"
                (keypress)="numberOnly($event)" (onChange)="onChange($event)"></textinput>
        </div>

        <div class="col-md-3">
            <textinput [layout]="layout" [field]="{name:'hausnummerzusatz',label:'Hausnummerzusatz',maxlength:4}"
                [form]="Form" (onChange)="onChange($event)"></textinput>
        </div>

        <div class="col-md-6">
            <textinput [layout]="layout" [field]="{name:'telefonnummer',label:'Telefon',maxlength:30}" [form]="Form"
                (keypress)="numberOnly($event)" (onChange)="onChange($event)"></textinput>
        </div>

        <div class="col-md-6">
            <textinput [layout]="layout" [field]="{name:'handynummer',label:'Telefon mobil',maxlength:20}" [form]="Form"
                (keypress)="numberOnly($event)" (onChange)="onChange($event)"></textinput>
        </div>

        <div *ngIf="mailHasFocus" class="col-md-12">
            <div class="bg-warning text-dark border border-secondary p-2 we_inlinehelp">
                Mit der Angabe Ihrer E-Mail-Adresse erklären Sie sich einverstanden, dass das STADTWERK AM SEE
                rechtserhebliche Erklärungen zur Begründung, Durchführung, Änderung oder Beendigung des
                Vertragsverhältnisses per E-Mail an Sie
                versendet (z.B. Mitteilungen
                über den Vertragsbeginn, monatliche Abrechnungen, etwaige Preis- oder Vertragsanpassungen etc.).
            </div>
        </div>

        <div class="col-md-6">
            <textinput [layout]="layout" [field]="{name:'email',label:'E-Mail-Adresse',maxlength:70}" [form]="Form"
                (onChange)="onChange($event)" (onFocus)="onFocus($event)" (onBlur)="onBlur($event)"></textinput>
        </div>

        <div class="col-md-6">
            <textinput [layout]="layout" [field]="{name:'_email',label:'Wiederholung E-Mail-Adresse',maxlength:70}"
                [form]="Form" (onChange)="onChange($event)"></textinput>

        </div>


        <ng-container *ngIf="Form.get('anrede').value!=='Firma'">
            <div class="col-md-6">
                <textinput [layout]="layout"
                    [field]="{name:'geburtsdatum',label:'Geburtsdatum',type:'date',maxlength:40}" [form]="Form"
                    (onChange)="onChange($event)"></textinput>
            </div>

            <div class="col-md-6">

            </div>
        </ng-container>


        <div *ngIf="kontoinhaberFocus" class="col-md-12">
            <div class="bg-warning text-dark border border-secondary p-2 we_inlinehelp">
                Um die Bargeldlos-Funktionen der GRÜNEN KARTE nutzen zu können, geben Sie bitte hier Ihre Kontodaten an.
            </div>
        </div>


        <div class="col-md-6">
            <textinput [layout]="layout" [field]="{name:'kontoinhaber',label:'Kontoinhaber',maxlength:70}" [form]="Form"
                (onChange)="onChange($event)" (onFocus)="onFocus($event)" (onBlur)="onBlur($event)"></textinput>
        </div>

        <div class="col-md-6">
            <textinput [layout]="layout" [field]="{name:'iban',label:'IBAN',maxlength:35}" [form]="Form"
                (onChange)="onChange($event)" (onBlur)="onBlur($event)"></textinput>
        </div>


        <div class="col-md-6">
            <textinput [layout]="layout" [field]="{name:'bic',label:'BIC',maxlength:11}" [form]="Form"
                (onChange)="onChange($event)"></textinput>
        </div>

        <div class="col-md-6">
            <textinput [layout]="layout" [readonly]="true"
                [field]="{name:'kreditinstitut',label:'Kreditinstitut',maxlength:58}" [form]="Form"
                (onChange)="onChange($event)"></textinput>
        </div>

        <div class="col-md-12">
            <b>*</b> Pflichtfelder sind mit dem Stern markiert
        </div>

        <div class="col-md-12">

            <div class="form-check">
                <input class="form-check-input we_checkbox" type="checkbox" value="" id="agb" formControlName="agb">
                <label class="form-check-label we_disclaimer" style="display: block" for="agb">
                    Hiermit akzeptiere ich die
                    <a class="we_link"
                        href="https://www.stadtwerk-am-see.de/friedrichshafenGips/StadtwerkAmSee/stadtwerk-am-see.de/Referenzierter-Inhalt/Allgemeinen-Bedingungen-GRUeNEN-KARTE.pdf"
                        target="_blank">Allgemeinen Bedingungen und
                        Datenschutzinformationen zur GRÜNEN KARTE</a>
                    des STADTWERKS AM SEE*
                </label>
            </div>
        </div>

        <div class="col-md-12" *ngIf="autostrom">

            <div class="form-check">
                <input class="form-check-input we_checkbox" type="checkbox" value="" id="autostromagb"
                    formControlName="autostromagb">
                <label class="form-check-label we_disclaimer" style="display: block" for="autostromagb">
                    Hiermit akzeptiere ich die
                    <a class="we_link"
                        href="https://www.stadtwerk-am-see.de/friedrichshafenGips/StadtwerkAmSee/stadtwerk-am-see.de/Referenzierter-Inhalt/Allgemeinen-Bedingungen-GRUeNEN-KARTE.pdf"
                        target="_blank">Allgemeinen Stromlieferbedingungen und
                        Datenschutzinformationen GRÜNE KARTE - MEIN AUTO STROM</a>
                    des STADTWERKS AM SEE*
                </label>
            </div>
        </div>

        <div class="col-md-12">

            <div class="form-check">
                <input class="form-check-input we_checkbox" type="checkbox" value="" id="opt_in_Newsletter"
                    formControlName="opt_in_Newsletter">
                <label class="form-check-label we_disclaimer" style="display: block" for="opt_in_Newsletter">
                    Ich erkläre mich einverstanden, dass mich das STADTWERK AM SEE zum Zwecke der <b>Marktforschung</b>
                    per E-Mail und telefonisch kontaktiert und hierzu die von mir im Rahmen dieses Vertrags erhobenen
                    Daten (z. B. Name,
                    Anschrift,
                    E-Mail-Adresse, Tel.-Nr.) verarbeitet. Die Einwilligung gilt bis zum Ende der Vertragslaufzeit,
                    sofern ich sie nicht vorher widerrufe. Ein solcher Widerruf ist jederzeit möglich. Er erfolgt für
                    die Zukunft und berührt
                    damit nicht die Rechtmäßigkeit der bis zum Widerruf erfolgten Verarbeitung. Der Widerruf ist zu
                    richten an STADTWERK AM SEE GmbH &amp; Co. KG<br><br>Kundenservice<br>Kornblumenstr. 7/1<br>88046
                    Friedrichshafen<br>Fax
                    07541 505-60219<br>service@stadtwerk-am-see.de<br><br>Weitere Informationen zur Verarbeitung
                    personenbezogener Daten durch das STADTWERK AM SEE sowie zu diesbezüglichen Widerspruchsrechten des
                    Kunden finden sich in den
                    dem Kunden zur Verfügung gestellten <a class="we_link"
                        href="https://www.stadtwerk-am-see.de/friedrichshafenGips/StadtwerkAmSee/stadtwerk-am-see.de/Referenzierter-Inhalt/Allgemeinen-Bedingungen-GRUeNEN-KARTE.pdf"
                        target="_blank">Allgemeinen Bedingungen und
                        Datenschutzinformationen zur GRÜNEN KARTE</a>.


                </label>
            </div>
        </div>

        <div class="col-md-12">

            <div class="form-check">
                <input class="form-check-input we_checkbox" type="checkbox" value="" id="opt_in_MaFo"
                    formControlName="opt_in_MaFo">
                <label class="form-check-label we_disclaimer" style="display: block" for="opt_in_MaFo">
                    Ich erkläre mich einverstanden, dass mich das STADTWERK AM SEE zum Zwecke der <b>Werbung für
                        Produkte und/oder Dienstleistungen</b>, Informationen über Sonderangebote und Rabattaktionen
                    des STADTWERKS AM SEE und seiner
                    Partnerunternehmen per E-Mail kontaktiert und hierzu die von mir im Rahmen dieses Vertrags erhobenen
                    Daten (z. B. Name, Anschrift, E-Mail-Adresse) verarbeitet. Die Einwilligung gilt bis zum Ende des
                    auf die
                    Vertragsbeendigung folgenden Kalenderjahres, sofern ich sie nicht vorher widerrufe. Ein solcher
                    Widerruf ist jederzeit möglich. Er erfolgt für die Zukunft und berührt damit nicht die
                    Rechtmäßigkeit der bis zum Widerruf
                    erfolgten Verarbeitung. Der Widerruf ist durch Klick des Abmeldungslinks im Newsletter zu tätigen
                    oder ist zu richten an STADTWERK AM SEE GmbH &amp; Co. KG<br><br>Kundenservice<br>Kornblumenstr.
                    7/1<br>88046
                    Friedrichshafen<br>Fax 07541 505-60219<br>service@stadtwerk-am-see.de<br><br>Weitere Informationen
                    zur Verarbeitung personenbezogener Daten durch das STADTWERK AM SEE sowie zu diesbezüglichen
                    Widerspruchsrechten des
                    Kunden finden sich in den dem Kunden zur Verfügung gestellten <a class="we_link"
                        href="https://www.stadtwerk-am-see.de/friedrichshafenGips/StadtwerkAmSee/stadtwerk-am-see.de/Referenzierter-Inhalt/Allgemeinen-Bedingungen-GRUeNEN-KARTE.pdf"
                        target="_blank">Allgemeinen
                        Bedingungen und Datenschutzinformationen zur GRÜNEN KARTE</a>.



                </label>
            </div>
        </div>



        <div class="col-md-4">
            <!--Senden-Button-->
            <button type="button" [disabled]="validationPending()" (click)="speichern()"
                class="btn btn-primary we_button" data-bs-toggle="tooltip" data-bs-placement="top"
                title="Weiter zur Zusammenfassung....">
                Weiter</button>
        </div>


        <div class="col-md-4 ">
            <div *ngIf="mode==1 && ((Form.get('kundennummerstrom').value=='') ||(Form.get('kundennummerwaerme').value=='')) "
                class="border border-primary text-primary p-2 we_inlineinfo">
                Haben Sie an alle Kundennummern gedacht? Tragen Sie unbedingt alle Kundennummern ein, um den maximalen
                Rabatt mit der GRÜNEN KARTE zu erhalten.
            </div>
        </div>

        <div class="col-md-4" *ngIf="validationPending()">
            <!--Bootstrap-Spinner-->
            <div class="d-flex justify-content-center">
                <div class="d-flex align-items-center">
                    <div class="spinner-border ms-auto p-4" role="status" aria-hidden="true"></div>
                    <strong class="ms-4"> Prüfen.... </strong>
                </div>

            </div>
        </div>




    </form>
</div>



<div *ngIf="step==2" class="container-md we_zusammenfassung">


    <div class="row g-3">
        <h1 class="we_header">Bestellung GRÜNE KARTE</h1>
        <div class="we_subheader">Beinahe geschafft!
            <span>Hier die Zusammenfassung:</span>
        </div>
    </div>
    <div class="we_zusammenfassung_title" *ngIf="mode==1">
        <div class="row">
            <div class="col-md-12 fw-bold">
                GRÜNE KARTE Energie und Energie plus für Kunden mit Energievertrag (0 Euro/Jahr)
            </div>
            <div class="col-md-12 fw-bold" *ngIf="autostrom">
                MEIN AUTO STROM, Grundpreis (1,95 EUR/Monat), Arbeitspreis (AC) (39,60 Ct/kWh), Arbeitspreis (DC) (49,60
                Ct/kWh)
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 text-nowrap we_label">Kundennummer Strom:</div>
            <div class="col-md-2 text-nowrap we_data">
                {{Form.value['kundennummerstrom']?Form.value['kundennummerstrom']:'--'}}</div>
        </div>

        <div class="row">
            <div class="col-md-4 text-nowrap we_label">Kundennummer Gas / Wärme:</div>
            <div class="col-md-2 text-nowrap we_data">
                {{Form.value['kundennummerwaerme']?Form.value['kundennummerwaerme']:'--'}}</div>
        </div>

        <div class="row mt-2"
            *ngIf="((Form.get('kundennummerstrom').value=='') ||(Form.get('kundennummerwaerme').value==''))">
            <div class="col-md-8">
                <div class="border border-primary text-primary p-2 text-center we_inlineinfo">
                    Haben Sie an alle Kundennummern gedacht? Tragen Sie unbedingt alle Kundennummern ein, um den
                    maximalen Rabatt mit der GRÜNEN KARTE zu erhalten.
                </div>
            </div>

        </div>




    </div>

    <div class="we_zusammenfassung_title" *ngIf="mode==2">
        <div class="row">
            <div class="col-md-12 fw-bold">
                GRÜNE KARTE HochZwei, Zweitkarte für Kunden mit Energievertrag (7,50 Euro/Jahr)
            </div>
            <div class="col-md-12 fw-bold" *ngIf="autostrom">
                MEIN AUTO STROM, Grundpreis (1,95 EUR/Monat), Arbeitspreis (AC) (39,60 Ct/kWh), Arbeitspreis (DC) (49,60
                Ct/kWh)
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 text-nowrap we_label">Kundennr. bestehende Grüne Karte:</div>
            <div class="col-md-2 text-nowrap we_data">
                {{Form.value['kundennummerbestehendengruenekarte']?Form.value['kundennummerbestehendengruenekarte']:'--'}}
            </div>
        </div>

        <div class="row">
            <div class="col-md-4 text-nowrap we_label">Kartennr. bestehende Grüne Karte:</div>
            <div class="col-md-2 text-nowrap we_data">
                {{Form.value['kartennummerbestehendengruenekarte']?Form.value['kartennummerbestehendengruenekarte']:'--'}}
            </div>
        </div>
    </div>

    <div class="we_zusammenfassung_title" *ngIf="mode==3">
        <div class="row">
            <div class="col-md-12 fw-bold">
                GRÜNE KARTE Basis, für Kunden ohne Energievertrag (15 Euro/Jahr)
            </div>
            <div class="col-md-12 fw-bold" *ngIf="autostrom">
                MEIN AUTO STROM, Grundpreis (1,95 EUR/Monat), Arbeitspreis (AC) (39,60 Ct/kWh), Arbeitspreis (DC) (49,60
                Ct/kWh)
            </div>
        </div>
    </div>




    <div class="row mt-4">
        <div class="col-md-12 fw-bold">
            Ihre persönlichen Daten
        </div>
    </div>


    <div class="row">
        <div class="col-md-2 we_label">Anrede:</div>
        <div class="col-md-2 we_data">{{Form.value['anrede']}}</div>
    </div>

    <ng-container *ngIf="Form.value['anrede']!='Firma'">
        <div class="row">
            <div class="col-md-2 we_label">Titel:</div>
            <div class="col-md-2 we_data">{{Form.value['titel']}}</div>
        </div>

        <div class="row">
            <div class="col-md-2 text-nowrap we_label">Vorname / Name:</div>
            <div class="col-md-2 text-nowrap we_data">{{Form.value['vorname']}} {{Form.value['name']}}</div>
        </div>

        <div class="row">
            <div class="col-md-2 text-nowrap we_label">Namenszusatz:</div>
            <div class="col-md-2 text-nowrap we_data">{{Form.value['namenszusatz']}}</div>
        </div>
    </ng-container>


    <ng-container *ngIf="Form.value['anrede']=='Firma'">
        <div class="row">
            <div class="col-md-2 text-nowrap we_label">Firmenname:</div>
            <div class="col-md-2 text-nowrap we_data">{{Form.value['firma']}}</div>
        </div>

        <div class="row">
            <div class="col-md-2 text-nowrap we_label">Ansprechpartner:</div>
            <div class="col-md-2 text-nowrap we_data">{{Form.value['ansprechpartner']}}</div>
        </div>
    </ng-container>


    <div class="row">
        <div class="col-md-2 text-nowrap we_label">PLZ / Ort:</div>
        <div class="col-md-2 text-nowrap we_data">{{Form.value['plz']}} {{Form.value['ort']}}</div>
    </div>

    <div class="row">
        <div class="col-md-2 text-nowrap we_label">Straße / Hausnr.:</div>
        <div class="col-md-2 text-nowrap we_data">{{Form.value['strasse']}} {{Form.value['hausnummer']}}</div>
    </div>

    <div class="row">
        <div class="col-md-2 text-nowrap we_label">Hausnr. Zusatz:</div>
        <div class="col-md-2 text-nowrap we_data"> {{Form.value['hausnummerzusatz']}}</div>
    </div>

    <div class="row">
        <div class="col-md-2 text-nowrap we_label">Telefon:</div>
        <div class="col-md-2 text-nowrap we_data"> {{Form.value['telefonnummer']}}</div>
    </div>
    <div class="row">
        <div class="col-md-2 text-nowrap we_label">Telefon mobil:</div>
        <div class="col-md-2 text-nowrap we_data"> {{Form.value['handynummer']}}</div>
    </div>

    <div class="row">
        <div class="col-md-2 text-nowrap we_label">E-Mail-Adresse:</div>
        <div class="col-md-2 text-nowrap we_data"> {{Form.value['email']}}</div>
    </div>

    <div class="row">
        <div class="col-md-2 text-nowrap we_label">Geburtsdatum:</div>
        <div class="col-md-2 text-nowrap we_data"> {{Form.value['geburtsdatum'] |date}}</div>
    </div>


    <div class="row mt-4">
        <div class="col-md-12 fw-bold">
            Bankverbindung
        </div>
    </div>


    <div class="row">
        <div class="col-md-2 text-nowrap we_label">Iban:</div>
        <div class="col-md-2 text-nowrap we_data"> {{Form.value['iban']}}</div>
    </div>

    <div class="row">
        <div class="col-md-2 text-nowrap we_label">BIC:</div>
        <div class="col-md-2 text-nowrap we_data"> {{Form.value['bic']}}</div>
    </div>

    <div class="row">
        <div class="col-md-2 text-nowrap we_label">Kredtinstitut:</div>
        <div class="col-md-2 text-nowrap we_data"> {{Form.value['kreditinstitut']}}</div>
    </div>


    <div class="row">
        <div class="col-md-2 text-nowrap we_label">Kontoinhaber:</div>
        <div class="col-md-2 text-nowrap we_data"> {{Form.value['kontoinhaber']}}</div>
    </div>


    <div class="we_zusammenfassung_footer">
        <div class="row g-3">

            <div class="col-md-2 me-2">
                <!--Senden-Button-->
                <button [disabled]="validationPending()" (click)="step=1" class="btn btn-secondary we_button_back"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Zurück zum Bestellformular">Zurück</button>
            </div>

            <div class="col-md-2 me-2">
                <!--Senden-Button-->
                <button [disabled]="validationPending()" (click)="absenden()" class="btn btn-primary we_button"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Weiter zur verbindlichen Bestellung">Bestellen</button>
            </div>


            <div class="col-md-2" *ngIf="validationPending()">
                <!--Bootstrap-Spinner-->
                <div class="d-flex">
                    <div class="d-flex align-items-center">
                        <div class="spinner-border ms-auto p-4" role="status" aria-hidden="true"></div>
                        <strong class="ms-4"> Senden.... </strong>
                    </div>

                </div>
            </div>




        </div>

        <div class="row mt-2">
            <div class="col-md-8">
                <div class="border border-primary text-primary p-2 text-center we_inlineinfo">
                    Mit einem Klick auf "Bestellen" geht es weiter zur verbindlichen Bestellung
                </div>
            </div>

        </div>
    </div>

</div>



<div *ngIf="step==3" class="container-md we_erfolgreich" style="min-height: 600px;">
    <div class="row g-3">
        <h3 class="we_header">Vielen Dank für die Bestellung Ihrer GRÜNEN KARTE!</h3>
        <h5 class="we_subheader">Ihre Bestellung wurde erfolgreich gesendet.</h5>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <p>Sie werden umgehend eine Bestätigungsmail von uns erhalten.<br> Sollte sich innerhalb der nächsten 24
                Stunden keine Nachricht von uns in Ihrem Posteingang befinden, überprüfen Sie bitte Ihren Spamordner auf
                eine Nachricht von
                uns.
            </p>
            <p>Um Ihre Bestellung zu <b>aktivieren</b>, klicken Sie bitte auf den dort vorhandenen Link.</p>
            <p><b>Beachten Sie bitte, dass die Bestellung ohne eine Aktivierung von uns nicht weiter bearbeitet werden
                    kann.</b></p>


        </div>
    </div>
</div>




<div *ngIf="ErrorMsg!==''" class="position-fixed top-0  p-3 text-center bg-danger text-white" style="width:100%">
    {{ErrorMsg}}</div>


<pagefooter></pagefooter>



<modalform [(visible)]="modalopen" [showcontinue]="modalshowcontinue" [title]="modaltitle" [message]="modalmessage"
    [type]="modaltype" [closebtn_visible]="modal_closebtn_visible" (closeclick)="onModalclose($event)"></modalform>