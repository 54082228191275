import { ValidationErrors, ValidatorFn, AbstractControl } from "@angular/forms";
import * as moment from 'moment';

export class CustomValidators {

    static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) {
                // if control is empty return no error
                return null;
            }

            // test the value of the control against the regexp supplied
            const valid = regex.test(control.value);

            // if true, return no error (no error), else return error passed in the second parameter
            return valid ? null : error;
        };
    }


    static emailValidator(control: AbstractControl) {

        if (!control.value) {
            return null;
        }

        if (control.value.length == 0 || control.value.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,8}$")) {
            return null;
        } else {
            return { 'invalidEmail': true };
        }
    }


    static passwordMatchValidator(control: AbstractControl) {
        const password: string = control.get("password").value;
        const password2: string = control.get("password2").value;
        if (password !== password2) {
            control.get("password2").setErrors({ NoPassswordMatch: true });
        }
    }


    static emailMatchValidator(control: AbstractControl): any | null {
        const email: string = control.get("email").value;
        const email2: string = control.get("_email").value;

        control.get("_email").setErrors(null);
        if ((email !== email2) && (email !== '') && (email2 !== '')) {
            control.get("_email").setErrors({ NoEmailMatch: true });
        }
    }


    static kdnrValidator(control: AbstractControl): any | null {

        //Prüft, ob mindestens EINE KundenNr angegeben wurde
        const kundennummerstrom: string = control.get("kundennummerstrom").value;
        const kundennummerwaerme: string = control.get("kundennummerwaerme").value;
        const mode: number = control.get("_mode").value;
        const autostrom: number = control.get("_autostrom").value;
        if (control.get("kundennummerwaerme").hasError('NoKdnrSelected'))
            control.get("kundennummerwaerme").setErrors(null)
        if ((kundennummerstrom == '') && (kundennummerwaerme == '')) {
            control.get("kundennummerwaerme").setErrors({ NoKdnrSelected: true });
        }
    }




    static dateTimeValidator(control: AbstractControl) {
        if ((control.value) && (control.value !== '')) {
            let m = moment(control.value, 'DD.MM.YYYY HH:mm');
            if (m.isValid()) {
                return null
            } else {
                return { invalidDateTime: true };
            }
        }
        return null; //Leeres datum ist OK.
    }


    static dateTimeValidatorStd(control: AbstractControl) {
        if ((control.value) && (control.value !== '')) {
            let m = moment(control.value, 'YYYY-MM-DD HH:mm');




            if (m.isValid()) {
                var years = moment().diff(m, 'years');


                if (years < 18) {
                    return { DateOutOfRange: true };
                }
                if (years > 130) {
                    return { DateOutOfRangeMax: true };
                }


                return null
            } else {
                return { invalidDateTime: true };
            }
        }
        return null; //Leeres datum ist OK.
    }




}
