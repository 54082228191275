
<pageheader></pageheader>
<div class="container content-width we_erfolgreich" style="min-height: 600px;">

    <ng-container *ngIf="step==1">

        <div class="row g-3 mt-3">
            <h3 class="we_header">Bestätigen Sie die Bestellung Ihrer GRÜNEN KARTE</h3>
            <h5 *ngIf="status=='open'" class="we_subheader">Nur noch ein letzter Schritt zur Ihrer GRÜNEN KARTE.</h5>
        </div>

        <div class="row mt-4">
            <div *ngIf="status=='open'" class="col-md-12">
                <p>Um Ihre Bestellung zu aktivieren, klicken Sie bitte auf <b>Vertrag bestätigen</b>.</p>
            </div>
            <div *ngIf="status=='completed'" class="col-md-12">
                <p>Ihre Bestellung ist bereits bestätigt. Sofern noch nicht geschehen, <br>wird die GRÜNE KARTE in Kürze bei Ihnen eintreffen.</p>
            </div>

            <div *ngIf="status=='not found'" class="col-md-12">
                <p>Die Bestellung ist in unserem System leider nicht vorhanden. <br><b>Bitte üperprüfen Sie die Adresse die Sie in der Adresszeile Ihres Browsers eingegeben haben.</b> </p>
            </div>




        </div>




        <div  *ngIf="status=='open'" class="row g-3">
            <div class="col-md-6">
                <!--Senden-Button-->
                <button style="width:100%" type="button" [disabled]="status!=='open'" (click)="activate()" class="btn btn-primary we_button" data-bs-toggle="tooltip" data-bs-placement="top" title="Aktivieren Sie Ihren Vertrag">Vetrag
                    bestätigen</button>
            </div>
        </div>


        <div class="row g-3 mt-3">
            <div class="col-md-6" *ngIf="false">
                <!--Bootstrap-Spinner-->
                <div class="d-flex justify-content-center">
                    <div class="d-flex align-items-center">
                        <div class="spinner-border ms-auto p-4" role="status" aria-hidden="true"></div>
                        <strong class="ms-4"> Aktivieren.... </strong>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>



    <ng-container *ngIf="step==2">

        <div class="row g-3 mt-3">
            <h3 class="we_header">Prima! Das hat geklappt.</h3>
        </div>

        <div class="row mt-4">
            <div class="col-md-12">
                <p>Vielen Dank für Ihre Bestellung. Wir produzieren nun Ihre persönliche GRÜNE KARTE.</p>
            </div>
        </div>
    </ng-container>






















</div>


<pagefooter></pagefooter>