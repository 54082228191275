import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';



@Component({
    selector: 'textinput',
    template: `

        <!--Layout 1 Label oben -->
        <div *ngIf="(layout<=1)"  [formGroup]="form">
            <label [for]="field.name" class="form-label we_label">{{field.label}}<span *ngIf="form.get(field.name)"> <strong *ngIf="isRequiredField(field.name)">*</strong></span></label>
            <input [readonly]="readonly" (focus)="Focus($event)" (blur)="Blur($event)" (change)="Change($event)" [type]="field.type?field.type:'text'" class="form-control we_input" [class.is-valid] = "checkValid(field)" autocomplete="{{autocomplete}}" [maxlength]="field.maxlength"  [id]="field.name" [formControlName]="field.name">
            <div class="text-danger" *ngIf="checkError(field)">
                {{checkError(field)}}
            </div>            
        </div>
        
        <!--Layout 2 Floating Labels -->
        <div *ngIf="layout==2" class="form-floating"  [formGroup]="form">
            <input [readonly]="readonly" (focus)="Focus($event)" (blur)="Blur($event)" (change)="Change($event)" [type]="field.type?field.type:'text'" class="form-control we_input" [class.is-valid] = "checkValid(field)"  autocomplete="{{autocomplete}}" [maxlength]="field.maxlength" [placeholder]="field.label" [id]="field.name" [formControlName]="field.name">            
            <label [for]="field.name" class="we_label">{{field.label}}<span *ngIf="form.get(field.name)"> <strong *ngIf="isRequiredField(field.name)">*</strong></span></label>
            <div class="text-danger" *ngIf="checkError(field)">
                {{checkError(field)}}
            </div>           
        </div>


        <!--Layout 3 NUR für Einspaltige, Formulare, Label links -->
        <div  *ngIf="layout==3" class="row mb-3"  [formGroup]="form">
            <label [for]="field.name"  class="col-sm-2 col-form-label we_label">{{field.label}}<span *ngIf="form.get(field.name)"> <strong *ngIf="isRequiredField(field.name)">*</strong></span></label>
            <div class="col-sm-10">
                <input [readonly]="readonly" (focus)="Focus($event)" (blur)="Blur($event)" (change)="Change($event)" [type]="field.type?field.type:'text'" class="form-control we_input" [class.is-valid] = "checkValid(field)" autocomplete="{{autocomplete}}" [maxlength]="field.maxlength" [placeholder]="field.label" [id]="field.name" [formControlName]="field.name">            
                <div class="text-danger" *ngIf="checkError(field)">
                    {{checkError(field)}}
                </div>            
            </div>
        </div>



    `
})
export class TextInputComponent {
    @Input() field: any = {};
    @Input() form: FormGroup;
    @Input() layout: number = 1;
    @Input() readonly: boolean = false;
    @Input() autocomplete: string = "off";

    get isValid() { return this.form.controls[this.field.name].valid; }
    get isDirty() { return this.form.controls[this.field.name].dirty; }

    @Output() onChange = new EventEmitter<string>();
    @Output() onFocus = new EventEmitter<string>();
    @Output() onBlur = new EventEmitter<string>();

    constructor() {

    }


    checkError(field): string {
        if (this.form.get(field.name).hasError('required') && this.form.get(field.name).touched)
            return field.label + ' muss angegeben werden'
        if (this.form.get(field.name).hasError('NoEmailMatch') && this.form.get(field.name).touched)
            return ' Die E-Mail-Adresse wurde nicht korrekt wiederholt'
        if (this.form.get(field.name).hasError('NoKdnrSelected') && this.form.get(field.name).touched)
            return ' Es muss mind. 1 Kundennummer angegeben werden'
        if (this.form.get(field.name).hasError('invalidDateTime') && this.form.get(field.name).touched)
            return ' Das angegebene Datum ist ungültig'
        if (this.form.get(field.name).hasError('DateOutOfRange') && this.form.get(field.name).touched)
            return ' Das gültige Alter beträgt mind. 18 Jahre'
        if (this.form.get(field.name).hasError('DateOutOfRangeMax') && this.form.get(field.name).touched)
            return ' Das Alter überschreitet den max. Wert.'
        if (this.form.get(field.name).hasError('InvalidIban') && this.form.get(field.name).touched)
            return 'Die IBAN-Nummer ist ungültig'
        if (this.form.get(field.name).hasError('invalidEmail') && this.form.get(field.name).touched)
            return 'Bitte geben Sie eine gültige E-Mail-Adresse an.'
        if (this.form.get(field.name).hasError('InvalidKundennummer') && this.form.get(field.name).touched)
            return 'Die Kundennummer ist ungültig'

        if (this.form.get(field.name).hasError('existingKartennrRequest') && this.form.get(field.name).touched)
            return 'Für diese Kundennummer existiert bereits eine Anfrage'
        if (this.form.get(field.name).hasError('invalidKartennr') && this.form.get(field.name).touched)
            return 'Die Kartennummer ist ungültig'
        if (this.form.get(field.name).hasError('unknownError') && this.form.get(field.name).touched)
            return 'Ein unbekannter Fehler ist aufgetreten'

        if (this.form.get(field.name).hasError('KartenNrerr') && this.form.get(field.name).touched)
            return 'Geben Sie bitte die dazu passende Kartennummer ein'

        return null



    }




    checkValid(field) {
        let fields = ['kundennummerstrom', 'kundennummerwaerme']
        return (this.form.get(field.name).valid) && (this.form.get(field.name).value != '') && (fields.includes(field.name))

    }


    Change(event) {
        this.onChange.emit(event)
    }

    Focus(event) {

        this.onFocus.emit(event)
    }

    Blur(event) {

        this.onBlur.emit(event)
    }





    isRequiredField(field: string) {
        //Ermittelt, ob das Feld "Required" ist
        const form_field = this.form.get(field);
        if (!form_field.validator) {
            return false;
        }
        const validator = form_field.validator({} as AbstractControl);
        return (validator && validator.required);
    }

}



