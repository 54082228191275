import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pagefooter',
  templateUrl: './pagefooter.component.html',
  styleUrls: ['./pagefooter.component.scss']
})
export class PagefooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
