
import { HttpClient } from '@angular/common/http';
// https://www.netjstech.com/2020/11/custom-async-validator-angular-reactive-form.html

import { Injectable, Component } from '@angular/core';
import { AbstractControl, AsyncValidator, FormGroup, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { DbconfigService } from './dbconfig.service';


@Injectable({ providedIn: 'root' })
export class KdnrGwsValidator implements AsyncValidator {


    constructor(private http: HttpClient, private config: DbconfigService) { }

    validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        return this.checkkdnr(control.value, control).pipe(
            catchError(() => of(null))
        );
    }



    //HTTP-Service
    checkkdnr(kdnr, control: AbstractControl): Observable<ValidationErrors | null> {
        if (!kdnr)
            return of(null)

        if (kdnr == '') {
            //Leere kdnr is OK
            return of(null)
        }
        if (kdnr.length != 8) {
            //kdnr kürzer 8 Stellen sind nicht gültig
            return of({ invalidKartennr: true })
        }


        if (control.parent.get('kundennummerstrom') == control) {
            //sql = 'Select * from ver_traege where strom is not null and knd_nr1="' + kdnr + '" order by status';

            let param = "?operation=kundennummerstrom";
            let json = '{"kdnr":"' + kdnr + '"}';
            let sql = JSON.stringify(JSON.parse(json));


            return this.http.post<any>(this.config.url + param, sql).pipe(
                map(
                    res => {
                        if (res["success"]) {
                            return null;
                        } else if (res["error"] == "existing request") {
                            return { existingKartennrRequest: true };
                        } else if (res["error"] == "kdnr not found") {
                            return { invalidKartennr: true };
                        } else {
                            return { unknownError: true };
                        }
                    }
                )
            )
        }
        if (control.parent.get('kundennummerwaerme') == control) {
            //sql = 'Select * from ver_traege where ((fernwaerme is not null) or (gas is not null))    and knd_nr1="' + kdnr + '" order by status';
            let param = "?operation=kundennummerwaerme";
            let json = '{"kdnr":"' + kdnr + '"}';
            let sql = JSON.stringify(JSON.parse(json));
            return this.http.post<any>(this.config.url + param, sql).pipe(
                map(
                    res => {
                        if (res["success"]) {
                            return null;
                        } else if (res["error"] == "existing request") {
                            return { existingKartennrRequest: true };
                        } else if (res["error"] == "kdnr not found") {
                            return { invalidKartennr: true };
                        } else {
                            return { unknownError: true };
                        }
                    }
                )
            )
        }
    }






}