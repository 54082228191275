import { User } from './user';
import { DbconfigService } from './dbconfig.service';

//https://medium.com/@ryanchenkie_40935/angular-authentication-using-route-guards-bf7a4ca13ae3

import { HttpClient, HttpHeaders } from "@angular/common/http";

import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt'; //npm install --save @auth0/angular-jwt

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public currentUserSubject = new BehaviorSubject<User>(JSON.parse(JSON.stringify(new User)));
  public currentUser: Observable<User>;

  public LoggedinUserSubject = new BehaviorSubject<boolean>(this.isAuthenticated());
  public Loggedin: Observable<boolean>;


  constructor(private http: HttpClient, private config: DbconfigService) {
    this.currentUser = this.currentUserSubject.asObservable();
    this.Loggedin = this.LoggedinUserSubject.asObservable();

  }


  //WICHTIG: muss EINMAL bei der Initialisierung der APP aufgerufen werden
  //Liest alle Bnutzerinformationen für die aktuelle Session anhand des JWT-Tokens ein
  public Init() {
    this.getUserFromToken(this.getToken()).subscribe(
      user => {
        this.currentUserSubject.next(user);
      }
    )
  }


  //Wird nur im AuthGuard für die Prüfung der Role benötigt
  //Liest die notwendigen Benutzerinfos (falls noch nicht vorhanden) aus der Datenbank
  public current_User(): Observable<User> {
    return this.currentUser.pipe(mergeMap(res => {
      if (!res.id) {
        return this.getUserFromToken(this.getToken())
      } else {
        return of(res);
      }
    }))
  }






  public getToken() {
    if (localStorage.getItem('token'))
      return localStorage.getItem('token')
    else
      return '';
  }


  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();//https://github.com/auth0/angular2-jwt
    if (!token)
      return false;
    return !helper.isTokenExpired(token);
  }


  getNewToken(email, password): Observable<any> {
    let param = "?operation=authenticate";
    let content = { email: email, password: password };
    let data = JSON.stringify(content);
    data = JSON.parse(data);

    let queryurl = this.config.url + param;
    return this.http.post<any>(queryurl, data)
      .pipe(map(result => {
        if (result) {

          localStorage.setItem('token', result['data'])
          return result['data'];
        } else {
          return null;
        }
      }))
  }



  getUserFromToken(token): Observable<User> {
    const helper = new JwtHelperService();//https://github.com/auth0/angular2-jwt
    let id = 0;
    if (!token)
      return of(JSON.parse(JSON.stringify(new User)));//Leeres UserObject
    id = helper.decodeToken(token).id;
    let param = "?operation=select";
    let queryurl = this.config.url + param;
    let data = 'Select id,username,vorname,nachname,password,email,administrator,verwaltung,aussendienst,gast from user where id=' + id;
    return this.http.post<User>(queryurl, data).pipe(
      map(res => {
        if (!res['data'][0]) {
          //User ist nicht mehr vorhanden, obwohl ein gültiges Token gesetzt. (Zwischenzeitlich aus der Datenbank gelöscht...)
          localStorage.removeItem('token'); //Token entfernen
          this.currentUserSubject.next(JSON.parse(JSON.stringify(new User))); //Leeren User and CurrentUser zurückgeben
          this.LoggedinUserSubject.next(false); //Login=false
          return (JSON.parse(JSON.stringify(new User))) //Leeren User als Ergebnis
        }

        this.currentUserSubject.next(res['data'][0])
        return res['data'][0];
      })
    )

  }



  doLogin(email, password): Observable<any> {
    return this.getNewToken(email, password).pipe(mergeMap(res => {
      this.LoggedinUserSubject.next(res !== null);
      return this.getUserFromToken(res)
    }
    ))
  }

  doLogout() {
    localStorage.removeItem('token');
    this.currentUserSubject.next(JSON.parse(JSON.stringify(new User)));
    this.LoggedinUserSubject.next(false);
  }





}
