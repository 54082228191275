<footer id="footer" class="hr-noprint">
    <div class="footer-addition">
        <div class="content-width">
            <div class="zeile">
                <div class="kasten spalte-50" id="kasten_7062">
                    <div class="kasten_top"></div>
                    <div class="kasten_content" id="kasten_content_7062">

                        <img src="assets/img/icon-kundenberatung-dunkel.svg"
                            loading="lazy"
                            onload="if (typeof(HREventManager) == 'object'){ HREventManager.dispatchEvent(this, 'resize', {notifySelf: false, bubbles: true, useStandard: false, notifyAll: true});}"
                            alt="Kundenberatung" class="float_left">

                        <p><b>Kundenberatung</b><br><a title="Service" rel="noopener"
                                href="mailto:service@stadtwerk-am-see.de"
                                target="_blank">service@stadtwerk-am-see.de</a><br>T <a title="Service" rel="noopener"
                                href="tel:+498005052000" target="_blank">0800 505 2000</a></p>

                    </div>
                    <div class="kasten_bottom"></div>
                </div>
                <div class="kasten spalte-50" id="kasten_7066">
                    <div class="kasten_top"></div>
                    <div class="kasten_content" id="kasten_content_7066">

                        <p><br>Montag bis Freitag von 08:00 bis 17:00 Uhr <a target="_blank"
                                href="https://www.stadtwerk-am-see.de/friedrichshafenGips/Gips?Anwendung=CMSWebpage&amp;Methode=ShowHTMLAusgabe&amp;RessourceID=159373&amp;SessionMandant=StadtwerkAmSee">persönlich</a>
                            für
                            Sie da.</p>
                    </div>
                    <div class="kasten_bottom"></div>
                </div>

            </div>
        </div>
    </div>
    <div class="footer-main">
        <div class="content-width">
            <ul class="footer-main-navigation">

                <li class=" ersterNaviEintrag no-children" data-ebene="1"><a target="_blank" id="id_1760835120"
                        href="https://www.stadtwerk-am-see.de/friedrichshafenGips/Gips;jsessionid=CE9B242F210C4D0A0F49621F3C054B02?SessionMandant=StadtwerkAmSee&amp;Anwendung=CMSWebpage&amp;Methode=ShowHTMLAusgabe&amp;RessourceID=7045&amp;WebPublisher.NavId=7042">Datenschutz</a>
                </li>


                <li class=" letzterNaviEintrag no-children" data-ebene="1"><a target="_blank" id="id_853807699"
                        href="https://www.stadtwerk-am-see.de/friedrichshafenGips/Gips;jsessionid=CE9B242F210C4D0A0F49621F3C054B02?SessionMandant=StadtwerkAmSee&amp;Anwendung=CMSWebpage&amp;Methode=ShowHTMLAusgabe&amp;RessourceID=7048&amp;WebPublisher.NavId=7043">Impressum</a>
                </li>


            </ul>
            <div class="footer-main-social-media">
                <p>Folgen Sie uns :</p>
                <div class="icons">

                    <a target="_blank" rel="noopener" title="Externer Link: Seite öffnet neues Fenster"
                        href="https://www.instagram.com/stadtwerkamsee/" class="NoFileIcon "
                        onclick="if (typeof HRLogAction == 'function') HRLogAction('SP=98af8443a87e56f341bf0e76156734482ee221ca1147829763c1f7a1c83d44564c6f908d3493b47f50c2a842015cf43110967b2a3935f965c9568b311c9212a7114ee3b99bcb705363b1f696c6a4a782');">
                        <img src="assets/img/icon-instagram-weiss.svg"
                            loading="lazy"
                            onload="if (typeof(HREventManager) == 'object'){ HREventManager.dispatchEvent(this, 'resize', {notifySelf: false, bubbles: true, useStandard: false, notifyAll: true});}"
                            alt="Instagram"> </a>


                    <a target="_blank" rel="noopener" title="Externer Link: Seite öffnet neues Fenster"
                        href="https://de-de.facebook.com/stadtwerkamsee" class="NoFileIcon "
                        onclick="if (typeof HRLogAction == 'function') HRLogAction('SP=54a2600c3ffa138de0097995b0b1ff411183ae084a84426630f4e9e5afbe02604c6f908d3493b47f50c2a842015cf43110967b2a3935f965c9568b311c9212a79654546007aafa27062b4b9f6e0abf31');">
                        <img src="assets/img/icon-facebook-weiss.svg"
                            loading="lazy"
                            onload="if (typeof(HREventManager) == 'object'){ HREventManager.dispatchEvent(this, 'resize', {notifySelf: false, bubbles: true, useStandard: false, notifyAll: true});}"
                            alt="Facebook"> </a>



                    <a target="_blank" rel="noopener" title="Externer Link: Seite öffnet neues Fenster"
                        href="https://www.youtube.com/channel/UCYBbBLxG73_V8GpIFQvDrMQ" class="NoFileIcon "
                        onclick="if (typeof HRLogAction == 'function') HRLogAction('SP=eb1c40cc3a51774b150115e09392ded46698869e5995d79ef83586ae75686e7c4c6f908d3493b47f50c2a842015cf43110967b2a3935f965c9568b311c9212a7141aa6f4616c1d1561e8cf9ec14a4fe0');">
                        <img src="assets/img/icon-youtube-weiss.svg"
                            loading="lazy"
                            onload="if (typeof(HREventManager) == 'object'){ HREventManager.dispatchEvent(this, 'resize', {notifySelf: false, bubbles: true, useStandard: false, notifyAll: true});}"
                            alt="YouTube"> </a>


                    <a target="_blank" rel="noopener" title="Externer Link: Seite öffnet neues Fenster"
                        href="https://twitter.com/STADTWERKAMSEE" class="NoFileIcon "
                        onclick="if (typeof HRLogAction == 'function') HRLogAction('SP=28072f6c77393777d4fb558d3351ed77fca98efdd6b23c32c3f4ac83f7d40b354c6f908d3493b47f50c2a842015cf43110967b2a3935f965c9568b311c9212a7ef1a99f70c47c28178f44e7717839882');">
                        <img src="assets/img/icon-twitter-weiss.svg"
                            loading="lazy"
                            onload="if (typeof(HREventManager) == 'object'){ HREventManager.dispatchEvent(this, 'resize', {notifySelf: false, bubbles: true, useStandard: false, notifyAll: true});}"
                            alt="Twitter"> </a>

                </div>
            </div>
        </div>
    </div>
</footer>